import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import withAnimation from '../../../hocs/withAnimation';
import Button from '../../Button';
import IconSVG from '../../../assets/icons/IconSVG';
import Highlight from '../../common/Highlight';

const AccountDataRow = ({ socialMediaName, accountName, connectionStatus, index, isDetailsOpened, setIsDetailsOpened }) => {
  const [detailMenuPosition, setDetailMenuPosition] = useState(0);
  const refDetailsMenu = useRef();

  const setDetailsMenuStatus = (index,e) => {
    setDetailMenuPosition(e.clientY);
    if (index == isDetailsOpened) {
      setIsDetailsOpened(-1);
    } else {
      setIsDetailsOpened(index);
    }
  };
  return (
    <>
      <td className="pl-10 sm:hidden xs:hidden md:hidden">
        {socialMediaName && (
          <div className="w-full flex h-14 justify-start items-center">
            <IconSVG
              className="w-9 h-9 rounded-full mr-2"
              alt="sas"
              name={socialMediaName}
            />
            <span className="text-sm">
              <Highlight text={socialMediaName} />
            </span>
          </div>
        )}
      </td>
      <td className="sm:pl-10 xs:pl-5 md:pl-5">
        <div className="w-full flex h-14 justify-start items-center">
          <img
            className="w-9 h-9 rounded-full mr-2"
            alt="sas"
            src="https://demos.pixinvent.com/vuexy-vuejs-admin-template/demo-1/assets/avatar-1-129659bb.png"
          />
          <span className="text-sm">
            <Highlight text={accountName} />
          </span>
        </div>
      </td>
      <td className="h-auto sm:pl-10 xs:pl-5">
        <div ref={refDetailsMenu} className= {`absolute ${window.innerHeight - detailMenuPosition > 170 ? 'top-8':'-top-[130px]' } right-5 z-50 w-36 h-36  rounded-lg drop-shadow-md ${isDetailsOpened === index ? 'visible': 'hidden'} md:hidden`}>
          <div  className={`absolute z-0 w-4 h-4 ${window.innerHeight - detailMenuPosition > 170 ? '-top-2 left-[124px] origin-center rotate-45':'top-[136px] left-[124px] origin-center rotate-[45deg]'  } bg-white dark:bg-gray-900`}></div>
          <ul className='text-sm select-none relative bg-cardBackground z-50'>
            <li className='h-12 -z-10 flex items-center border-borderColor border-b hover:bg-gray-50 dark:hover:bg-gray-900 h-cursor-p rounded-t-lg'>
              <div id='detailOption' className='h-full w-full items-center pl-4 flex'>
                <IconSVG id='detailOption' name='disconnect' className='mr-2 h-full'/>
                Disconnect
              </div>
            </li>
            <li className='h-12 -z-10 flex items-center border-borderColor border-b hover:bg-gray-50 dark:hover:bg-gray-900 h-cursor-p rounded-t-lg'>
              <div id='detailOption' className='h-full w-full items-center pl-4 flex'>
                <IconSVG id='detailOption' name='trash' className='mr-2 h-full'/>
                Delete
              </div>
            </li>
            <li className='h-12 -z-10 flex items-center border-borderColor border-b hover:bg-gray-50 dark:hover:bg-gray-900 h-cursor-p rounded-t-lg'>
              <div id='detailOption' className='h-full w-full items-center pl-4 flex'>
                <IconSVG id='detailOption' name='details' className='mr-2 h-full'/>
                Details
              </div>
            </li>
          </ul>
        </div>
        <div
          onClick={(e)=>setDetailsMenuStatus(index,e)}
          className={'w-fit flex justify-end absolute right-0 top-3 xl:hidden 2xl:hidden md:hidden lg:hidden h-cursor-p'}
        >
          <IconSVG
            id="detailButton"
            name="verticalMore"
            fill="gray"
            className="min-w-[30px] min-h-[30px] w-full"
          />
        </div>

        <div className="flex items-center h-14">
          <span className="relative flex h-3 w-3">
            <span
              className={`animate-ping absolute inline-flex h-full w-full rounded-full ${
                connectionStatus.toLowerCase() === 'connected'
                  ? 'bg-green-500'
                  : 'bg-red-500'
              } opacity-75`}
            ></span>
            <span
              className={`relative inline-flex rounded-full h-3 w-3 ${
                connectionStatus.toLowerCase() === 'connected'
                  ? 'bg-green-500'
                  : 'bg-red-500'
              }`}
            ></span>
          </span>
          <span className="text-xs h-[17px] ml-2 ">
            <Highlight text={connectionStatus} />
          </span>
        </div>
      </td>
      <td className="items-center justify-start h-14 sm:hidden xs:hidden">
        <div className="w-full flex justify-start space-x-2">
          <Button textClasses={'md:hidden lg:hidden ml-2'}
            icon="details"
            iconColor="white"
            iconAndButtonPlacement="justify-between"
            additionalClasses="bg-blue-500 h-8 w-auto py-4 px-2 rounded text-white text-sm sm:hidden xs:hidden"
          >
            Details
          </Button>
          <Button textClasses={'md:hidden lg:hidden ml-2'}
            icon="trash"
            iconColor="white"
            iconAndButtonPlacement="justify-between"
            additionalClasses="bg-red-500 h-8 w-auto py-4 px-2 rounded text-white text-sm sm:hidden xs:hidden"
          >
            Delete
          </Button>
          <Button textClasses={'md:hidden lg:hidden ml-2'}
            icon={
              connectionStatus.toLowerCase() === 'connected'
                ? 'disconnect'
                : 'connected'
            }
            iconColor="white"
            iconAndButtonPlacement="justify-between"
            additionalClasses={`${
              connectionStatus.toLowerCase() === 'disconnected'
                ? 'bg-green-500 w-auto'
                : 'bg-orange-500 w-auto'
            } h-8 py-4 px-2 rounded text-white text-sm`}
          >
            {connectionStatus.toLowerCase() === 'disconnected'
              ? 'Connect'
              : 'Disconnect'}
          </Button>
        </div>
      </td>
    </>
  );
};

AccountDataRow.propTypes = {
  socialMediaName: PropTypes.string,
  accountName: PropTypes.string,
  connectionStatus: PropTypes.string,
  searchInputText: PropTypes.string,
  index: PropTypes.number,
  isDetailsOpened: PropTypes.number,
  setIsDetailsOpened: PropTypes.func,
};

AccountDataRow.defaultProps = {
  socialMediaName: 'facebook',
  accountName: '',
  connectionStatus: '',
  searchInputText: ''
};

const item = {
  hidden: {
    opacity: 0,
    translateY: 20
  },
  visible: {
    opacity: 1,
    translateY: 0
  }
};

const animationConfigures = {
  variants: item,
  className: 'bg-bodyColor text-center h-14 border-b border-borderColor even:bg-[#F5F5F5] dark:even:bg-bodyBackground relative'
};

export default withAnimation(AccountDataRow, 'tr', animationConfigures);
