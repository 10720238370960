/* eslint-disable */
import React from "react";
import { motion } from "framer-motion";

const withAnimation = (WrappedComponent, type, configures) => (props) => {
  const AnimatedComponent = motion[type];

  return (
    <AnimatedComponent {...configures}>
      <WrappedComponent {...props} />
    </AnimatedComponent>
  );
};

export default withAnimation;
