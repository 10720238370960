/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import IconSVG from '../../../assets/icons/IconSVG';
import Button from '../../Button';
import { toggleModal } from '../../../actions';
import { CONNECTION_SETTINGS_MODAL } from '../../../constants/Modals';
import withAnimation from '../../../hocs/withAnimation';
import ConnectionTable from './ConnectionTable';
import SearchInput from '../../common/SearchInput';

const Configures = () => {
  const dispatch = useDispatch();
  const [isSortedStatus, setIsSortedStatus] = useState(true);
  const [isSortedSocialName, setIsSortedSocialName] = useState(true);
  const [isSortedAccountName, setIsSortedAccountName] = useState(true);
  const userDatas = require('./accountDatas.json');

  const handleStatusSort = (sortedState, setSortedState, sortedField) => {
    setSortedState(!sortedState);
    if (sortedState) {
      userDatas.sort((a, b) => a[sortedField].localeCompare(b[sortedField]));
    } else {
      userDatas.sort((a, b) => b[sortedField].localeCompare(a[sortedField]));
    }
  };

  const openConnectionSettingsModal = () => {
    window.history.pushState(null, '', './connections');
    dispatch(
      toggleModal({
        id: CONNECTION_SETTINGS_MODAL,
        value: true,
      })
    );
  };

  return (
    <>
      <span className="text-primaryTextColor font-semibold text-lg">
        Connections
      </span>
      <div className="w-full h-auto pt-6">
        <div className="w-full flex lg:flex-col md:flex-col sm:flex-col xs:flex-col">
          <div className="w-6/12 md:w-full sm:w-full lg:w-full xs:w-full">
            <span className="text-secondaryTextColor w-10/12">
              Configure your account settings and preferences here!
            </span>
          </div>

          <div className="w-6/12 items-center flex justify-end space-x-5 md:w-full sm:w-full md:mt-2 lg:w-full lg:mt-2 sm:mt-2 xs:mt-2 xs:w-full xs:justfiy-center xs:items-center pr-[2px]">
            <SearchInput />
            <Button
              onClick={openConnectionSettingsModal}
              additionalClasses="w-[170px] py-2 px-2 bg-[#38cab3] hover:bg-[#5cd3b9] text-white text-md rounded xs:w-[50%] xs:text-[14px] xs:mt-0 sm:mt-0 sm:text-[14px]"
              iconSize={20}
              icon="plus"
              iconAndButtonPlacement="justify-between"
              iconColor="white"
            >
              Add a Connection
            </Button>
          </div>
        </div>

        <div className="w-full mt-5 h-auto">
          <table className="w-full border border-borderColor table-fixed">
            <thead className="bg-[#0000000c] dark:bg-[#00000093]">
              <tr className="w-full h-14 relative xs:text-xs">
                <th className="!font-semibold text-primaryTextColor text-left pl-10 sm:hidden xs:hidden md:hidden">
                  <div className=" flex items-center space-x-2">
                    <span>Social Name</span>
                    <IconSVG
                      onClick={() => {
                        handleStatusSort(
                          isSortedSocialName,
                          setIsSortedSocialName,
                          'socialMediaName'
                        );
                      }}
                      name="change"
                      className="h-cursor-p select-none"
                      width={20}
                      height={20}
                    ></IconSVG>
                  </div>
                </th>
                <th className="!font-semibold text-primaryTextColor text-left sm:pl-10 xs:pl-5 md:pl-5">
                  <div className=" flex items-center space-x-2">
                    <span>Account Name</span>
                    <IconSVG
                      onClick={() => {
                        handleStatusSort(
                          isSortedAccountName,
                          setIsSortedAccountName,
                          'accountName'
                        );
                      }}
                      name="change"
                      className="h-cursor-p select-none"
                      width={20}
                      height={20}
                    ></IconSVG>
                  </div>
                </th>
                <th className="!font-semibold text-primaryTextColor text-left sm:pl-10 xs:pl-5 ">
                  <div className=" flex items-center space-x-2">
                    <span>Status</span>
                    <IconSVG
                      onClick={() => {
                        handleStatusSort(
                          isSortedStatus,
                          setIsSortedStatus,
                          'connectionStatus'
                        );
                      }}
                      name="change"
                      className="h-cursor-p select-none"
                      width={20}
                      height={20}
                    ></IconSVG>
                  </div>
                </th>
                <th className="!font-semibold text-primaryTextColor text-left sm:hidden xs:hidden">
                  Manage
                </th>
              </tr>
            </thead>
            <ConnectionTable userDatas={ userDatas } />
          </table>
        </div>
      </div>
    </>
  );
};

const animationConfigures = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  className: 'h-[80vh] min-w-[270px]'
};

export default withAnimation(Configures, 'div', animationConfigures);
