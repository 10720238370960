import { all } from 'redux-saga/effects';

import app from './appSaga';
import social from './socialSaga';
import user from './userSaga';

export default function* rootSaga () {
  yield all([
    ...app,
    ...social,
    ...user,
  ]);
}
