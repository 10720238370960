import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ProtectedRoutes from './services/ProtectedRoutes';
import LandingPage from './views/LandingPage';
import Panel from './components/Panel/';
import { changeLeftMenuStatus, changeMobileStatus, initAppRequest } from './actions';
import ModalsUnion from './containers/ModalsUnion';
import RegisterPage from './components/Landing/Register';
import LoginPage from './components/Landing/Login';
import { getAppReady } from './selectors';
import UnprotectedRoutes from './services/UnprotectedRoutes';
import FBLoginv2 from './views/FBLoginv2';
import ErrorBoundary from './components/ErrorBoundary';
import { ErrorPage } from './components/ErrorPage';
import history from './utils/history';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
    exact: true,
  },
  {
    path: '/',
    element: <ProtectedRoutes />,
    children: [
      {
        path: '/dashboard/:componentName?',
        element: <Panel/>,
      },
      {
        path: '/fbloginv2',
        element: <FBLoginv2 />,
      },
    ]
  },
  {
    path: '/',
    element: <UnprotectedRoutes />,
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/register',
        element: <RegisterPage />,
      },
    ]
  },
  {
    path: '*',
    element: <ErrorPage/>
  }
]);

function App () {
  const dispatch = useDispatch();

  const { isMobile } = useSelector((state) => state.app);
  const isAppReady = useSelector(getAppReady);

  useEffect(() => {
    dispatch(initAppRequest());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 1024;
      if (newIsMobile !== isMobile) {
        dispatch(changeMobileStatus(newIsMobile));
        dispatch(changeLeftMenuStatus(!newIsMobile));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, dispatch]);

  const theme = useSelector((state) => state.app.theme);
  document.querySelector('html').setAttribute('class', theme);

  if (!isAppReady) {
    return (
      <div className='loading-container'>
        <span data-title='SOCIIZE' className='loading-fill text-[40px] font-bold animate-pulse'>SOCIIZE</span>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <ModalsUnion />
      <RouterProvider router={router} history={history} />
    </ErrorBoundary>
  );
}

export default App;
