/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const pricingOnClick = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      const pricingComponent = document.getElementById('pricing-component');
      const yOffset = -20; // İstediğiniz kaydırma miktarı, sayfanın en üstüne tam denk gelmesi için ayarlayabilirsiniz
      const y =
        pricingComponent.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  return (
    <div className="w-full flex sticky top-0 bg-white font-poppins font-medium">
      <div className="w-11/12 flex lg:w-full md:w-full sm:w-full pt-4  pr-5">
        <div className="w-[45%] lg:w-[20%] md:w-[20%] sm:w-[20%] flex  justify-center lg:justify-start ">
          <Link to="/">
            <img width={190} src={require('../../assets/pngFolder/logo2.png')} />
          </Link>
        </div>
        <div className="w-[47%] lg:w-[79%] md:w-[80%] sm:w-[80%] flex justify-end items-center font-manrope">
          <ul className="flex space-x-8 items-center h-full">
            <li>Product</li>
            <li>Templates</li>
            <li className="h-cursor-p" onClick={pricingOnClick}>
              Pricing
            </li>
            <li>
              <Link to="/login">
                Login
              </Link>
            </li>
            <li className="w-[120px] font-medium h-11 rounded-full bg-[#ff4a00] text-white flex items-center justify-center">
              <Link to="/login">
                Start Free
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
