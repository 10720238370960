/* eslint-disable no-undef */
import React from 'react';

const Social = () => {
  return (
    <div className='h-[69vh] w-full bg-white flex sm:flex-col sm:p-10 sm:h-auto md:items-center md:justify-center  '>

      <div className='w-6/12 flex flex-col items-end h-full sm:w-full lg:items-center lg:w-6/12'>
        <div className='w-[72%] h-full  font-manrope centered-items  pl-14 sm:w-full sm:p-0 md:w-full lg:w-full md:p-0 '>
          <ul className='space-y-9'>
            <li className='text-lg text-gray-500 '>Social Media</li>
            <li className='text-4xl font-semibold lg:text-3xl md:text-3xl '>Grow your social media followers</li>
            <li>
              <p className='text-lg text-ligth text-gray-500 lg:text-base md:text-base '>To reach the right people you need the right tools. With Woorise, you’ve got everything you need to connect with your fans and find new ones. Engage and grow your audience using a wide range of pre-build social actions, follow buttons and embedded social widgets for the most popular social platforms such as Facebook, Instagram, YouTube, Twitter, LinkedIn and more.</p>
            </li>
          </ul>
        </div>
      </div>
      <div className='w-5/12 flex justify-center sm:w-full md:w-5/12 md:flex md:items-center md:justify-center lg:w-6/12'>
        <div className='w-11/12 centered-items sm:w-full md:w-full md:items-center lg:w-full'>
          <img  src={require('../../assets/pngFolder/social.png')} className='min-w-[300px]' />
        </div>
      </div>
    </div>
  );
};

export default Social;
