import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DarkMode from './DarkMode';
import IconSVG from '../../../assets/icons/IconSVG';
import { changeLeftMenuStatus } from '../../../actions/pageActions';

const TopBar = () => {
  const { isLeftMenuOpened } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  return (
    <div className='w-full min-h-[65.8px] sticky top-0 z-50 left-[600px] flex drop-shadow-sm bg-bodyBackground border-b-borderColor border-b z-1'>
      <div className='w-full flex items-center pl-5'>
        <IconSVG className="w-fit h-cursor-p h-5 fill-gray-400 dark:fill-gray-200"
          onClick={()=>dispatch(changeLeftMenuStatus(!isLeftMenuOpened))}
          name="toggle"
        />  <DarkMode/>
      </div>

    </div>
  );
};

export default TopBar;
