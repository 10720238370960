import { createSelector } from 'reselect';

export const getUi = state => state.ui;

export const getIsFacebookConnected = createSelector(
  getUi,
  ui => ui.facebookConnection === true
);

export const getModals = createSelector(getUi, ui => {
  return ui.modals ?? {};
});

export const getSearchInputText = createSelector(getUi, ui => {
  return ui.searchInputText ?? '';
});
