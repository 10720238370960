import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../Button';
import IconSVG from '../../../assets/icons/IconSVG';
import { userRegisterRequest } from '../../../actions/userActions';
import Input from '../../Input';
import { isValidEmail, isValidString } from '../../../utils';
import { getUser } from '../../../selectors';
import { clearUserError as clearUserErrorAction } from '../../../actions/uiActions';

const Register = ({ setUserGatePage }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const clearUserError = () =>{
    dispatch(clearUserErrorAction());
  };

  const handlePasswordVisibility = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const confirmPasswordVisibility = (e) => {
    e.preventDefault();
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const [userData, setUserData] = useState({
    password: '',
    email: '',
    fullName: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    password: false,
    email: '',
    fullName: '',
  });

  const onChangeHandler = (e) =>{
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNavigation = () => {
    setUserGatePage('login');
  };

  const inputsCheck = () => {
    let errors = {
      password: false,
      email: '',
      fullName: '',
    };

    if (!isValidString(userData.fullName, 6)) {
      errors = {
        ...errors,
        fullName: 'Full name must be longer than 6 character.',
      };
    }
    if (!isValidEmail(userData.email)) {
      errors = { ...errors, email: 'This e-mail is not valid.' };
    }
    if (
      !isValidString(userData.password, 8) ||
      userData.password !== userData.confirmPassword
    ) {
      errors = { ...errors, password: true };
    }

    setError(errors);
    const isInputsValid = Object.values(errors).every(value => value === false || value === '');

    if (!isInputsValid) {
      return false;
    }

    return true;
  };

  const sendRegisterRequest = (e) => {
    e.preventDefault();
    if (!inputsCheck()) {
      return false;
    }

    dispatch(
      userRegisterRequest({
        email: userData.email,
        password: userData.password,
        fullName: userData.fullName,
      })
    );
  };

  useEffect(() => {
    if (user.token) {
      window.location.href = '/dashboard';
    }

    return () => {};
  }, [user]);

  return (
    <div className="w-full h-full flex flex-col items-center xs:px-2">
      <div className="w-full flex-col flex items-center mb-6">
        <span className="text-2xl font-poppins font-semibold text-primaryTextColor">
          Register with E-mail
        </span>
        <span className="text-sm text-grayTextColor mt-3">
          Manage your social accounts as a professional.
        </span>
      </div>
      <form className='w-full' onSubmit={sendRegisterRequest}>
        <div className="w-full">
          <Input
            name={'fullName'}
            onChange={onChangeHandler}
            additionalClasses=""
            title="Full Name"
            inputAdditionalClasses={`active:ring-slate-400 outline-blue-300 focus-within:outline-5 ${
              error.fullName && '!border-red-400 border-2'
            }`}
          />
          {error.fullName.length > 0 && (
            <span className="text-xs text-red-400 pb-4">{error.fullName}</span>
          )}
        </div>
        <div className="w-full">
          <Input
            name={'email'}
            onChange={onChangeHandler}
            title="E-mail"
            additionalClasses=""
            inputAdditionalClasses={`active:ring-slate-400 outline-blue-300 focus-within:outline-5 ${
              error.email && '!border-red-400 border-2'
            }`}
          />
          {error.email.length > 0 && (
            <span className="text-xs text-red-400">{error.email}</span>
          )}
        </div>
        <div className="w-full relative flex flex-col">
          <Input
            name={'password'}
            title="Password"
            onChange={onChangeHandler}
            type={isPasswordVisible ? 'text' : 'password'}
            additionalClasses="!pb-1"
            inputAdditionalClasses={`!relative active:ring-slate-400 outline-blue-300 focus-within:outline-5 ${
              error.password && '!border-red-400 border-2'
            }`}
          ></Input>
          <button
            onClick={handlePasswordVisibility}
            className={`absolute top-[38px] centered-items w-7 right-1 h-7 group/passwordButton hover:bg-[#4496f33a] rounded before:absolute ${
              !isPasswordVisible
                ? 'before:content-[\'Show_Password\']'
                : 'before:content-[\'Hide_Password\']'
            } before:invisible hover:before:visible before:-top-6 before:text-xs before:text-grayTextColor before:w-24 `}
          >
            <IconSVG
              className="fill-grayTextColor w-5 h-5 group-hover/passwordButton:fill-blue-600"
              name={!isPasswordVisible ? 'closedEye' : 'openedEye'}
            />
          </button>
          <span className="text-xs text-grayTextColor font-poppins">
          Your password must contain the following
          </span>
          <ul className="text-xs text-grayTextColor font-poppins">
            <li className="flex items-center">
              <div
                className={`w-3 h-3 ${
                  userData.password.length < 1
                    ? 'bg-gray-400'
                    : userData.password.length > 0 && userData.password.length < 8
                      ? 'bg-red-400'
                      : 'bg-green-400'
                } rounded-full mr-1`}
              ></div>
            Min 8 character
            </li>
            <li className="flex items-center">
              <div
                className={`w-3 h-3 ${
                  userData.password.length < 1
                    ? 'bg-gray-400'
                    : userData.password === userData.confirmPassword
                      ? 'bg-green-400'
                      : 'bg-red-400'
                } rounded-full mr-1`}
              ></div>
            Match with confirm password
            </li>
          </ul>
        </div>
        <div className="w-full space-y-2 relative">
          <Input
            name='confirmPassword'
            title="Confirm Password"
            onChange={onChangeHandler}
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            additionalClasses="!pt-2"
            inputAdditionalClasses="active:ring-slate-400 outline-blue-300 focus-within:outline-5"
          ></Input>

          <div
            onClick={confirmPasswordVisibility}
            className={`absolute top-[38px] centered-items w-7 right-1 h-7 group/confirmButton before:absolute ${
              !isConfirmPasswordVisible
                ? 'before:content-[\'Show_Password\']'
                : 'before:content-[\'Hide_Password\']'
            } before:invisible hover:before:visible before:-top-6 before:text-xs before:text-grayTextColor before:w-24 hover:bg-[#4496f33a] rounded`}
          >
            <IconSVG
              className="fill-grayTextColor w-5 h-5 group-hover/confirmButton:fill-blue-600"
              name={!isConfirmPasswordVisible ? 'closedEye' : 'openedEye'}
            />
          </div>
        </div>
        <span className={`text-xs w-full text-blue-400 h-6 flex items-center ${user.error ? 'visible':'invisible'} `} >{user.error}</span>
        <Button
          type={'submit'}
          additionalClasses="h-14 w-full text-white bg-[#38cab3] hover:bg-[#5cd3b9] centered-items rounded font-bold"
        >
        Register
        </Button>
      </form>
      <div className="w-full centered-items text-sm mt-5 text-primaryTextColor font-semibold">
        Do you have an account?
        {setUserGatePage ? (
          <span
            onClick={handleNavigation}
            className="ml-2 text-[#38cab3] h-cursor-p"
          >
            Login
          </span>
        ) : (
          <Link onClick={clearUserError} className="ml-2 text-[#38cab3] h-cursor-p" to={'/login'}>
            Login
          </Link>
        )}
      </div>
    </div>
  );
};

Register.propTypes = {
  setUserGatePage: PropTypes.func,
};

Register.defaultProps = {
  setUserGatePage: false,
};

export default Register;
