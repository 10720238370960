import { FACEBOOK_LOGIN_SUCCESS } from '../actionTypes';

const INITIAL_STATE = {
  facebook: null,
};

export default function socialReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FACEBOOK_LOGIN_SUCCESS:
    return {
      ...state,
      facebook: action.payload
    };
  default:
    return state;
  }
}
