import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconSVG from '../../../assets/icons/IconSVG';
import { changeThemeRequest } from '../../../actions/pageActions';

const DarkMode = () => {
  const theme = useSelector((state) => state.app.theme);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(changeThemeRequest(theme === 'light' ? 'dark' : 'light'));
  };

  return (
    <div onClick={handleClick} className="dark_mode">
      <IconSVG className="w-5 dark:fill-orange-600 fill-gray-500"  name={theme === 'light' ? 'moon' : 'sun'} />
    </div>
  );
};

export default DarkMode;
