import React from 'react';

import IconSVG from '../../../assets/icons/IconSVG';
import {
  Card,
  CardBody,
  CardHeader,
  CardLeftSide,
  CardRightSide,
} from '../../Card';

const Timeline = () => {
  return (
    <div className="bg-cardBackground rounded-md w-full p-4 px-2 h-full">
      <span className="text-grayTextColor  text-poppins font-semibold text-sm pl-2  h-fit">
        TIMELINE
      </span>
      <div className="w-fulll before:dark:bg-gray-600 before:bg-gray-200 before:absolute before:w-[2px] h-fit before:h-5/6 relative before:left-[23px]  before:mt-4">
        <div className="w-full relative flex before:left-8 h-auto p-4 ">
          <div className="z-40 w-full">
            <div className="space-y-1 w-full">
              <div className="w-full flex h-12 ">
                <div className="h-full flex items-center">
                  <div className="before:bg-white before:absolute  relative before:p-1 before:z-1 centered-items">
                    <IconSVG
                      name="instagramColorless"
                      className="z-10"
                      fill="#f74f75"
                    />
                  </div>
                </div>
                <Card additionalClasses="w-11/12 !p-0 ml-2 flex">
                  <CardLeftSide className="w-11/12 p-2 text-xs">
                    <CardHeader additionalClasses="font-semibold">
                    User Paylaştı.
                    </CardHeader>
                    <CardBody>
                      <p className="w-full truncate text-grayTextColor">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry
                    standard dummy text ever since the 1500s,
                      </p>
                    </CardBody>
                  </CardLeftSide>
                  <CardRightSide className="w-2/12 flex items-start justify-end px-1">
                    <span className="text-xs text-grayTextColor">10:12</span>
                  </CardRightSide>
                </Card>
              </div>

              <div className="w-full flex h-12 ">
                <div className="h-full flex items-center">
                  <div className="bg-blue-600 w-[15px]  h-[15px]  rounded  centered-items">
                    <IconSVG
                      name="facebookColorless"
                      className="z-10"
                      fill="white"
                    />
                  </div>
                </div>
                <Card additionalClasses="w-11/12 !p-0 ml-2 flex ">
                  <CardLeftSide className="w-11/12 p-2 text-xs">
                    <CardHeader additionalClasses="font-semibold">
                    User Paylaştı.
                    </CardHeader>
                    <CardBody>
                      <p className="w-full truncate text-grayTextColor">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry&#39;s
                    standard dummy text ever since the 1500s,
                      </p>
                    </CardBody>
                  </CardLeftSide>
                  <CardRightSide className="w-2/12 flex items-start justify-end px-1">
                    <span className="text-xs text-grayTextColor">10:12</span>
                  </CardRightSide>
                </Card>
              </div>

              <div className="w-full flex h-12  ">
                <div className="h-full flex items-center">
                  <div className="bg-blue-400 w-[15px]  h-[15px] rounded centered-items">
                    <IconSVG
                      name="twitterColorless"
                      className="z-10"
                      fill="white"
                      width={10}
                      height={10}
                    />
                  </div>
                </div>
                <Card additionalClasses="w-11/12 !p-0 ml-2 flex ">
                  <CardLeftSide className="w-11/12 p-2 text-xs">
                    <CardHeader additionalClasses="font-semibold">
                    User Paylaştı.
                    </CardHeader>
                    <CardBody>
                      <p className="w-full truncate text-grayTextColor">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry
                    standard dummy text ever since the 1500s,
                      </p>
                    </CardBody>
                  </CardLeftSide>
                  <CardRightSide className="w-2/12 flex items-start justify-end px-1">
                    <span className="text-xs text-grayTextColor">10:12</span>
                  </CardRightSide>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
