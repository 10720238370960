export const isValidEmail = (email) => {
  var emailPattern = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;

  return emailPattern.test(email);
};

export const isValidString = (text, length = 0) => {
  if (typeof text !== 'string' || !text || text.length < length) {
    return false;
  }
  return true;
};
