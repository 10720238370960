import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CONNECTION_SETTINGS_MODAL, USER_GATE_MODAL } from '../constants/Modals';
import UserGateModal from '../components/Modals/UserGateModal';
import { getModals } from '../selectors';
import { UI_TOGGLE_MODAL } from '../actionTypes';
import ConnectionSettingsModal from '../components/Modals/ConnectionSettingsModal';

const ModalComponentsMap = {
  [USER_GATE_MODAL]: UserGateModal,
  [CONNECTION_SETTINGS_MODAL]: ConnectionSettingsModal
};

const ModalsUnion = () => {
  const dispatch = useDispatch();
  const modals = useSelector(getModals);

  const closeModal = modalID => {
    dispatch({
      type: UI_TOGGLE_MODAL,
      payload: {
        modalID,
        value: false,
      },
    });
  };

  return (
    <>
      {
        Object.keys(ModalComponentsMap).map((modalKey) => {
          const ModalComponent = ModalComponentsMap[modalKey];

          return ModalComponent && modals[modalKey]?.value && <ModalComponent key={modalKey} data={modals[modalKey]?.data} closeModal={closeModal} />;
        })
      }
    </>
  );
};

export default ModalsUnion;
