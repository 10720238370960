import React, { useState } from 'react';

import IconSVG from '../../../assets/icons/IconSVG';

const Checkbox = () => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="w-10 h-10 centered-items  select-none">
      <div
        onClick={() => setIsChecked(!isChecked)}
        className={`w-9 h-9 rounded-full h-cursor-p hover:bg-[#00021f07] dark:hover:bg-gray-600 centered-items  transition-all ${
          isChecked ? 'active:bg-[#1778ff70] dark:active:bg-[#1778ff70]'  : 'active:bg-[#00021f14] dark:active:bg-[#00021f72]'
        }`}
      >
        <div
          className={`w-5 h-5 border transition-all border-gray-300 dark:border-gray-500 rounded ${
            isChecked && 'bg-[#1778ff] border-none'
          } border  centered-items`}
        >
          <IconSVG
            name="checkType2"
            fill="white"
            className={isChecked ? 'visible' : 'hidden'}
            width={16}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
