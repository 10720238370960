import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalHeader, ModalHeaderContent, ModalTitle, ModalCloseButton, ModalBody } from './';
import { getModals } from '../../../selectors';
import { toggleModal } from '../../../actions';

const ModalLayout = ({
  children,
  modalKey,
  title,
  headerIcon,
  modalClassNames,
  modalHeaderClassNames,
  modalBodyClassNames,
  modalHeaderContentClassNames,
  headerIconClassNames,
  modalTitleClassNames,
  modalCloseButtonClassNames,
}) => {
  const dispatch = useDispatch();
  const getModal = useSelector(getModals);
  const getModalStatus = getModal[modalKey]?.value;

  const handleModalClose = () => {
    dispatch(
      toggleModal({
        id: modalKey,
        value: false,
      })
    );
  };

  useEffect(() => {
    const handlePopState = () => {
      if (getModalStatus) {
        handleModalClose();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [getModalStatus]);

  return (
    <Modal modalKey={modalKey} className={modalClassNames} onRequestClose={handleModalClose}>
      <ModalHeader className={modalHeaderClassNames}>
        <ModalHeaderContent className={modalHeaderContentClassNames}>
          <ModalTitle icon={headerIcon} iconClassNames={headerIconClassNames} className={modalTitleClassNames}>
            {title}
          </ModalTitle>
          <ModalCloseButton onClick={handleModalClose} modalCloseButtonClassNames={modalCloseButtonClassNames}/>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalBody className={modalBodyClassNames}>
        {children}
      </ModalBody>
    </Modal>
  );
};

ModalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  modalKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  headerIcon: PropTypes.string,
  onClose: PropTypes.func,
  modalClassNames: PropTypes.string,
  modalHeaderClassNames: PropTypes.string,
  modalBodyClassNames: PropTypes.string,
  modalHeaderContentClassNames: PropTypes.string,
  headerIconClassNames: PropTypes.string,
  modalTitleClassNames: PropTypes.string,
  modalCloseButtonClassNames: PropTypes.string
};

ModalLayout.defaultProps = {
  title: '',
  headerIcon: '',
  onClose: () => false,
  modalClassNames: '',
  modalHeaderClassNames: '',
  modalBodyClassNames: '',
  modalHeaderContentClassNames: '',
  headerIconClassNames: '',
  modalTitleClassNames: '',
  modalCloseButtonClassNames: ''
};

export default ModalLayout;
