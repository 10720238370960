import React from 'react';

import ModalLayout from '../common/Modal/ModalLayout';
import UserGate from '../Panel/UserGate';
import { USER_GATE_MODAL } from '../../constants/Modals';

const UserGateModal = () => {
  return (
    <ModalLayout
      modalKey={USER_GATE_MODAL}
      modalHeaderContentClassNames={'flex justify-between items-center'}
    >
      <UserGate />
    </ModalLayout>
  );
};

export default UserGateModal;
