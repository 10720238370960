import { CLEAR_USER_ERROR, SET_SEARCH_INPUT_TEXT, UI_TOGGLE_MODAL } from '../actionTypes';

export const toggleModal = ({ id, value, data = null }) => {
  return {
    type: UI_TOGGLE_MODAL,
    payload: {
      id,
      value,
      data,
    }
  };
};

export const setSearchInputText = text => {
  return {
    type: SET_SEARCH_INPUT_TEXT,
    payload: text
  };
};

export const clearUserError = () => {
  return {
    type: CLEAR_USER_ERROR,
  };
};
