/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import withAnimation from '../../../../hocs/withAnimation';
import SocialMediaCard from './SocialMediaCard';
import { getModals } from '../../../../selectors';
import { CONNECTION_SETTINGS_MODAL } from '../../../../constants/Modals';

const ConnectionSettings = () => {
  const modals = useSelector(getModals);
  const connectionSettingsModal = modals[CONNECTION_SETTINGS_MODAL]?.value;
  const isModalOpened = connectionSettingsModal ? true: false;
  const connectionDatas = require('./connectionDatas.json');
  const [selectedMenu, setSelectedMenu] = useState('all');

  const handleSelectedMenu = (menu) => {
    setSelectedMenu(menu);
  };

  return (
    <>
      <span className={`text-primaryTextColor font-semibold text-lg ${isModalOpened && 'hidden'}`}>
      Manage Your Connections
      </span>
      <ul className="flex space-x-6 border-b border-borderColor px-2 text-sm select-none ml-1 mt-5">
        <li
          onClick={() => handleSelectedMenu('all')}
          className={`${
            selectedMenu === 'all'
              ? '!border-black dark:!border-borderColor'
              : ''
          } hover:border-black dark:border-transparent dark:hover:border-borderColor border-transparent h-cursor-p border-b-2 w-8 flex items-center justify-center`}
        >
            All
        </li>
        <li
          onClick={() => handleSelectedMenu('connected')}
          className={`${
            selectedMenu === 'connected'
              ? '!border-black dark:!border-borderColor'
              : ''
          } hover:border-black dark:border-transparent dark:hover:border-borderColor border-transparent h-cursor-p border-b-2 w-32 flex items-center justify-center`}
        >
            Only Connected
        </li>
      </ul>
      <div className={` ${isModalOpened && 'min-w-[800px] max-w-[800px] min-h-[600px] max-h-[600px] md:min-w-[601px] sm:min-w-[420px] xs:min-w-fit xs:max-h-fit'}`}>
        <div className={` ${isModalOpened && 'max-h-[600px] overflow-auto'} xs:max-h-[82vh] connectionContent flex-wrap flex`}>
          {
            connectionDatas.map((connection,index) =>{
              if (selectedMenu === 'all') {
                return (
                  <SocialMediaCard
                    key={index}
                    socialMediaName={connection.socialMediaName}
                    iconName={connection.socialMediaName}
                  />
                );
              } else if (selectedMenu === 'connected') {
                return (
                  connection.connectionStatus === 'connected' && (
                    <SocialMediaCard
                      socialMediaName={connection.socialMediaName}
                      iconName={connection.socialMediaName}
                    />
                  )
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

const container = {
  visible: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const animationConfigures = {
  initial: 'hidden',
  animate: 'visible',
  variants: container,
  className: 'text-primaryTextColor'
};

export default withAnimation( ConnectionSettings , 'div', animationConfigures) ;
