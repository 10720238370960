import React from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../assets/icons/IconSVG';

const PasswordVisibility = ({ isPasswordVisible, setIsPasswordVisible }) => {
  const handlePasswordVisibility = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <button
      type='button'
      onClick={handlePasswordVisibility}
      className={`absolute top-[30px] centered-items w-7 right-1 h-7 group/passwordButton hover:bg-[#4496f33a] rounded before:absolute ${!isPasswordVisible
        ? 'before:content-[\'Show_Password\']'
        : 'before:content-[\'Hide_Password\']'
      } before:invisible hover:before:visible before:-top-6 before:text-xs before:text-grayTextColor before:w-24 `}
    >
      <IconSVG
        className="fill-grayTextColor w-5 h-5 group-hover/passwordButton:fill-blue-600"
        name={!isPasswordVisible ? 'closedEye' : 'openedEye'}
      />
    </button>
  );
};

PasswordVisibility.propTypes = {
  isPasswordVisible: PropTypes.bool,
  setIsPasswordVisible: PropTypes.func,
};

PasswordVisibility.defaultProps = {
  isPasswordVisible: false,
  setIsPasswordVisible: () => {},
};

export default PasswordVisibility;
