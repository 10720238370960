/* eslint-disable */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import Landing from '../components/Landing';
import LandingMobile from '../components/LandingMobile';

function LandingPage () {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    document.title = 'Sociize | Easiest way to manage your social media accounts';
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // Ekran boyutu değiştiğinde handleResize fonksiyonunu çağır
    window.addEventListener('resize', handleResize);

    // Component kaldırıldığında event listener'ı temizle
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /* <Modal openNewPostModal={openNewPostModal} setOpenNewPostModal={setOpenNewPostModal} />
      <Navi setOpenNewPostModal={()=> setOpenNewPostModal(true)} /> */
  const [openNewPostModal, setOpenNewPostModal] = useState(false);
  return (
    <div>
      {
        screenSize.width < 767 ? <LandingMobile /> : <Landing />
      }

    </div>
  );
}

export default LandingPage;
