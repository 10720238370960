import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PasswordVisibility from './PasswordVisibility';

const Input = ({ type, title, onChange, additionalClasses, isPassword, inputAdditionalClasses, name, placeholder }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const getType = (type) => {
    if (type === 'password') {
      return isPasswordVisible ? 'text' : 'password';
    }
    return type;
  };

  const getID = (title, type) => {
    const preparedTitle = title.toLowerCase().replace(/ /g, '-');
    return `${preparedTitle}-${type}`;
  };

  return (
    <div className={`w-full space-y-2 ${additionalClasses}`}>
      <label htmlFor={getID(title, type)} className="w-full text-primaryTextColor text-xs font-semibold">
        {title}
      </label>
      <input
        placeholder={placeholder}
        name={name}
        id={getID(title, type)}
        className={`w-full h-10 border-gray-400 border rounded px-3 text-sm bg-cardBackground ${inputAdditionalClasses}`}
        type={getType(type)}
        onChange={onChange}
      />
      {isPassword && (
        <PasswordVisibility
          key={getID(title, type)}
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVisible={setIsPasswordVisible}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isPassword: PropTypes.bool,
  additionalClasses: PropTypes.string,
  inputAdditionalClasses: PropTypes.string,
};

Input.defaultProps = {
  placeholder: '',
  name: '',
  type: 'text',
  title: '',
  onChange: () => {},
  isPassword: false,
  additionalClasses: '',
  inputAdditionalClasses: '',
};

export default Input;
