import {
  SET_THEME_TYPE,
  INIT_APP_SUCCESS,
  SET_MODAL_STATUS,
  SET_LEFTMENU_STATUS,
  SET_MOBILE_STATUS,
  INIT_APP_FAILURE,
} from '../actionTypes';

const INITIAL_STATE = {
  theme: 'light',
  isReady: false,
  isModalOpen: false,
  isLeftMenuOpened: !(window.innerWidth < 1024),
  isMobile: window.innerWidth < 1024,
};

export default function appReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case INIT_APP_SUCCESS:
    return {
      ...state,
      isReady: action.payload.isReady,
      userAuth: true
    };
  case INIT_APP_FAILURE:
    return {
      ...state,
      isReady: action.payload.isReady,
      userAuth: false
    };
  case SET_THEME_TYPE:
    return {
      ...state,
      theme: action.payload,
    };
  case SET_MODAL_STATUS:
    return {
      ...state,
      isModalOpen: action.payload,
    };
  case SET_LEFTMENU_STATUS:
    return {
      ...state,
      isLeftMenuOpened: action.payload,
    };
  case SET_MOBILE_STATUS:
    return {
      ...state,
      isMobile: action.payload,
    };
  default:
    return state;
  }
}
