import React from 'react';

import IconSVG from '../../assets/icons/IconSVG';

const FirstPage = () => {
  return (
    <div className="h-[calc(100vh-70px)] w-full sm:h-auto">
      <div className="w-11/12 xl:p-4 flex sm:flex-col-reverse h-[75%] md:w-full lg:w-full sm:w-full sm:h-auto  sm:justify-center items-center " >
        <div className="w-[55%] lg:w-[45%] md:w-[50%] sm:w-full h-full  justify-center flex flex-col items-end lg:items-start">
          <div className="w-[70%] lg:w-full sm:w-full md:w-full p-3">
            <h1 className="text-5xl font-semibold md:text-3xl sm:text-2xl">
              Manage Online Platforms Posts, Tweets & Analytics
            </h1>
            <p className="text-[#5b5b5b] mt-5 text-lg lg:text-base md:text-base sm:text-base">
              Take control of your Social Media with our all-in-one platform.
              Easily manage, analyze, and engage your audience. Schedule bulk
              sharing, create interactive campaigns, and track performance with
              detailed analytics. Seamlessly integrate payments and enjoy the
              power of efficient social media management, all in a single
              platform.
            </p>
            <div className="w-11/12 h-fit flex mt-5 ">
              <div className="bg-[#ff4a00]  w-[140px] h-[60px] centered-items font-semibold text-xl text-white rounded-full">Start Free</div>
            </div>
          </div>
        </div>
        <div className="w-[38%] lg:w-[55%] md:w-[55%]  sm:w-full sm:p-10 sm:items-center md:items-center lg:items-center h-full flex items-center justify-center">
          <div className="w-[96%] lg:w-[75%] md:w-[85%] sm:w-full lg:p-0 py-12 pr-10 pl-8 md:p-0 sm:p-0 ">
            <IconSVG name={'welcomeImage'}  />

          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
