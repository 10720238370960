import React from 'react';
import PropTypes from 'prop-types';

const CardRightSide = ({ children, className }) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

CardRightSide.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

CardRightSide.defaultProps = {
  className: ''
};

export default CardRightSide;
