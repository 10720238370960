import { SET_LEFTMENU_STATUS, SET_MOBILE_STATUS, SET_MODAL_STATUS, SET_THEME_TYPE } from '../actionTypes';

export const changeThemeRequest = payload => ({
  type: SET_THEME_TYPE,
  payload
});

export const changeModalStatus = payload => ({
  type: SET_MODAL_STATUS,
  payload

});

export const changeLeftMenuStatus = payload => ({
  type: SET_LEFTMENU_STATUS,
  payload
});

export const changeMobileStatus = payload => ({
  type: SET_MOBILE_STATUS,
  payload
});
