/* eslint-disable no-undef */
import React from 'react';

import IconSVG from '../../assets/icons/IconSVG';

const Pricing = () => {
  const pricingData = require('../Landing/pricingSettings.json');
  return (
    <div className="w-full h-auto p-5 centered-items ">
      <div className="grid grid-cols-1 h-auto gap-2 p-5 ">
        {pricingData.map((e, index) => (
          <div
            key={index}
            className={`w-full max-w-[345px] min-w-[260px] h-auto p-5 flex flex-col items-center    drop-shadow-lg border ${
              e.isMostPopular ? 'bg-orange-100' : e.isRecommended && 'bg-blue-100'
            } `}
          >
            <div
              className={`w-full centered-items ${
                !e.isMostPopular && !e.isRecommended && 'invisible'
              } `}
            >
              <span className={`p-1 rounded-md text-xs centered-items bg-[#ff4400] ${e.isRecommended && 'bg-blue-500'} text-white `}>
                {e.isMostPopular ? 'Most Popular' : e.isRecommended && 'Recommended'}
              </span>
            </div>

            <span className="font-semibold text-sm  centered-items w-full mt-2 ">
              {e.mainTitle}
            </span>
            <span className="font-semibold text-lg w-full centered-items text-4xl ">
              {e.Price}
            </span>
            <p className="text-xs w-full  text-center">{e.subTitle}</p>
            <div className={`w-2/6 bg-[#ff4400] ${e.isRecommended && 'bg-blue-500'} rounded p-1 centered-items mt-4`}>
              <span className="text-white text-xs">Buy It</span>
            </div>
            <div className="w-full h-auto border-t border-t-orange-400 mt-2 space-y-6 pl-2 mt-5 ">
              {
                e.attributes.map((attribute, index)=>(
                  <div key={index} className={`flex items-center mt-2 ${!attribute.isHave && 'opacity-30'} text-sm space-x-2`}>
                    <IconSVG name="check" fill={attribute.isHave ? '#0ebb63' : 'gray'} className="" />
                    <span>{attribute.attributeName}</span>
                  </div>
                ))
              }

            </div>
          </div>

        ))}
      </div>
    </div>
  );
};

export default Pricing;
