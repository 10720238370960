export const INIT_APP_REQUEST = 'INIT_APP_REQUEST';
export const INIT_APP_SUCCESS = 'INIT_APP_SUCCESS';
export const INIT_APP_FAILURE = 'INIT_APP_FAILURE';

export const INIT_USER_REQUEST = 'INIT_USER_REQUEST';
export const INIT_USER_SUCCESS = 'INIT_USER_SUCCESS';
export const INIT_USER_FAILURE = 'INIT_USER_FAILURE';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const FACEBOOK_LOGIN_REQUEST = 'FACEBOOK_LOGIN_REQUEST';
export const FACEBOOK_LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_FAILURE = 'FACEBOOK_LOGIN_FAILURE';

export const SET_MODAL_STATUS =  'SET_MODAL_STATUS';
export const SET_THEME_TYPE = 'SET_THEME_TYPE';
export const SET_LEFTMENU_STATUS = 'SET_LEFTMENU_STATUS';
export const SET_MOBILE_STATUS = 'SET_MOBILE_STATUS';

export const UI_TOGGLE_MODAL = 'UI_TOGGLE_MODAL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const SET_SEARCH_INPUT_TEXT = 'SET_SEARCH_INPUT_TEXT';

export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';
