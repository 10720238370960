import React from 'react';
import { Provider } from 'react-redux';

import './index.css';
import './style/style.css';
import App from './App';
import store from './stores';
import root from './constants/root';

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
