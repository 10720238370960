import React from 'react';
import PropTypes from 'prop-types';

const CardLeftSide = ({ children, className }) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

CardLeftSide.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

CardLeftSide.defaultProps = {
  className: ''
};

export default CardLeftSide;
