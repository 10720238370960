import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import React from 'react';

import reducer from '../reducers';
import rootSaga from '../sagas';
import ErrorBoundary from '../components/ErrorBoundary';
import root from '../constants/root';

class SagaError extends Error {
  constructor (previeousError, sagaStack) {
    super(previeousError.message, { cause: previeousError });
    this.stack = previeousError.stack;
    this.sagaStack = sagaStack;
  }
}

const ThrowerComponent = ({ error }) => {
  throw error;
};

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    root.render (
      <ErrorBoundary>
        <ThrowerComponent error={new SagaError(error, sagaStack)} />
      </ErrorBoundary>
    );
  }
});

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
