import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../../assets/icons/IconSVG';
import Button from '../../Button';

const MessageWindow = ({ isMessageOpened, setIsMessageOpened, message }) => {
  const [text, setText] = useState('');

  const handleTextareaChange = (e) => {
    setText(e.target.value);

    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };
  return (
    <div className={`${isMessageOpened === -1 ? '-right-[600px] drop-shadow-none':'right-0 md:w-full lg:w-full sm:w-full xs:w-full' } h-full w-[600px] absolute rounded bg-bodyBackground transition-all z-40 border-l border-borderColor drop-shadow-xl `}>
      <div id='header' className='w-full border-borderColor border-b h-[60px]'>
        <ul className='h-full px-5 pr-6'>
          <li onClick={()=>setIsMessageOpened(-1)} className='h-full flex items-center'>
            <ul className='flex h-full items-center space-x-2 w-full'>
              <li className='h-cursor-p'>
                <span><IconSVG name={'leftArrow'} fill='gray' className='w-5 h-5'/></span>
              </li>
              <li className='w-full'>
                <div className='flex items-center'>
                  <img
                    className="w-8 h-8 rounded-full"
                    alt="sas"
                    src="https://demos.pixinvent.com/vuexy-vuejs-admin-template/demo-1/assets/avatar-1-129659bb.png"
                  />
                  <span className='ml-1 text-primaryTextColor'>{message?.userName}</span>

                </div>
              </li>
              {message?.labels.map((label, index) => (
                <li key={index} className={`${label[1]}  flex w-auto p-1 rounded`}>
                  <span className='text-gray-100 text-xs'>{label[0]}</span>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>

      <div id='container' className='bg-bodyColor flex flex-col w-full h-[calc(100%-60px)] p-6 space-y-2'>
        <div className='w-full h-[94%] bg-bodyBackground space-y-10 p-5 rounded-lg'>
          <div className='bg-gray-500/60 w-fit p-2 rounded-lg text-white'>{message?.message}</div>
        </div>

        <div className='flex h-fit rounded justify-between w-full space-x-4 mb-6 items-end'>
          <div className='flex items-center rounded h-auto w-10/12'>
            <textarea
              style={{ height: 'auto' }}
              rows={1}
              value={text}
              onChange={handleTextareaChange}
              placeholder="Metin girin..."
              className='w-full max-h-[200px] text-secondaryTextColor rounded-md h-4 outline-none bg-bodyBackground p-1 align-middle resize-none'/>
          </div>
          <Button additionalClasses='w-2/12 rounded h-8 text-white bg-[#38cab3] hover:bg-[#5cd3b9]'>Gönder</Button>
        </div>
      </div>

    </div>
  );
};

MessageWindow.propTypes = {
  isMessageOpened: PropTypes.number,
  message: PropTypes.object,
  setIsMessageOpened: PropTypes.func
};

export default MessageWindow;
