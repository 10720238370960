import React from 'react';

import Timeline from './Timeline';
import Comments from './Comments';
import WelcomeCard from './WelcomeCard';
import withAnimation from '../../../hocs/withAnimation';

const Dashboard = () => {
  return (
    <>
      <span className="text-primaryTextColor font-semibold text-lg">
        DASHBOARD
      </span>
      <div className="grid grid-cols-12 gap-4">
        <div className='col-span-5 xl:col-span-7 lg:col-span-7 md:col-span-12 sm:col-span-12 xs:col-span-12'><WelcomeCard/></div>
        <div className='col-span-3 xl:col-span-5 lg:col-span-5 md:col-span-6 sm:col-span-12 xs:col-span-12'><Comments /></div>
        <div className='col-span-4 xl:col-span-7 lg:col-span-7 md:col-span-6 sm:col-span-12 xs:col-span-12'><Timeline /></div>
      </div>
    </>
  );
};

const animationConfigures = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  className: 'h-full z-0'
};

export default withAnimation(Dashboard, 'div', animationConfigures);
