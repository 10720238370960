import { call, put, take, takeEvery } from 'redux-saga/effects';

import {
  INIT_APP_FAILURE,
  INIT_APP_REQUEST,
  INIT_APP_SUCCESS,
  INIT_USER_REQUEST,
  INIT_USER_SUCCESS,
} from '../actionTypes';
import api from '../api';
import { getData } from '../api/utils';

function* initApp () {
  try {
    const { isTokenValid } = getData(yield call(api.authUser));
    if (!isTokenValid) {
      throw new Error('Token is invalid');
    }

    yield put({ type: INIT_USER_REQUEST });
    yield take(INIT_USER_SUCCESS);
    yield put({ type: INIT_APP_SUCCESS, payload: { isReady: isTokenValid } });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield put({ type: INIT_APP_FAILURE, payload: { isReady: true } });
  }
}

const appSaga = [
  takeEvery(INIT_APP_REQUEST, initApp)
];

export default appSaga;
