import React, { useState } from 'react';

import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const InboxPanel = () => {
  const [isLeftPanelOpened, setIsLeftPanelOpened] = useState(false);
  const [labelNames, setLabelNames] = useState({
    'personal-label': 'Personal',
    'private-label': 'Private',
    'company-label': 'Company',
    'important-label': 'Important'
  });

  const messages = [
    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
      labels: [
        [
          labelNames['personal-label'],
          'bg-green-400'
        ],
        [
          labelNames['private-label'],
          'bg-red-500'
        ]
      ]
    },
    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
      labels: [
        [
          labelNames['important-label'],
          'bg-orange-600'
        ],
        [
          labelNames['company-label'],
          'bg-[#7864f4]'
        ]
      ]
    },
    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
    },
    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
    },
    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
    },    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
    },    {
      userName: '@BilmemNe',
      message: 'Content content',
      lastMessageDate: 'June 12',
      isRead: false,
    }
  ];

  return (
    <div className="w-full h-[80vh] md:w-full flex justify-center mt-2 drop-shadow-md relative sm:w-full xs:w-full overflow-hidden">
      <div className={`w-2/12 h-auto min-w-[245px] ${isLeftPanelOpened == false && window.innerWidth < 1024 && '!-left-[300px] !z-0'} md:absolute sm:h-full sm:absolute xs:absolute xs:left-0 xs:h-full z-50 md:left-0 sm:left-0 md:h-full md:drop-shadow-lg transition-all`}>
        <LeftPanel labelNames={labelNames} setLabelNames={setLabelNames} isLeftPanelOpened={isLeftPanelOpened} setIsLeftPanelOpened={setIsLeftPanelOpened} />
      </div>
      <div className="w-10/12 h-auto bg-bodyBackground relative md:w-full sm:w-full xs:w-full">
        <div onClick={()=>setIsLeftPanelOpened(false)} className={`bg-black opacity-50 w-full h-full absolute left-0 rounded-l-lg hidden md:block sm:block xs:block ${isLeftPanelOpened == false && '!hidden'}`} />
        <RightPanel messages={messages} isLeftPanelOpened={isLeftPanelOpened} setIsLeftPanelOpened={setIsLeftPanelOpened}/>
      </div>
    </div>
  );
};

export default InboxPanel;
