/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconSVG from '../../assets/icons/IconSVG';
import { changeLeftMenuStatus } from '../../actions';
import { getIsLeftMenuOpened, getIsMobile } from '../../selectors';

const LeftMenu = ({ selectedMenu }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const isLeftMenuOpened = useSelector(getIsLeftMenuOpened);
  const [widthValue, setWidthValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLeftMenuOpened) {
      changeWidthValue(240);
    } else if (!isLeftMenuOpened) {
      changeWidthValue(80);
    }
  }, [isMobile, isLeftMenuOpened]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile]);

  const handleClickOutside = (e) => {
    if (e.clientX > 240 && e.clientY > 65 && isMobile) {
      dispatch(changeLeftMenuStatus(false));
    }
  };

  const changeWidthValue = (value) => {
    setWidthValue(value + 'px');
  };

  const handleNavigate = (componentName) =>{
    navigate('/dashboard/'+componentName);
  };

  const textCssText = widthValue !== '240px' ? 'pl-[9px] hidden' : 'pl-[9px]';
  return (
    <div
      style={{ width: isMobile ? '0px' : widthValue }}
      className={'max-w-[240px] w-[240px] transition-all h-auto z-50'}
    >
      <div
        style={{ display: isMobile && 'none' }}
        className={'h-[65px] min-w-[240px] centered-items drop-shadow-2xl bg-bodyBackground border-r-[1px] border-borderColor sticky top-0'}
      >
        <img
          className="w-8/12"
          src={require('../../assets/pngFolder/logo2.png')}
          alt="logo"
        />
      </div>
      <div
        style={{
          width: isMobile && widthValue === '80px' ? '0px' : widthValue,
          position: isMobile && 'absolute',
          top: isMobile && '65px',
        }}
        className={'bg-bodyBackground h-[calc(100%-65px)] transition-all border-r-[1px] border-t border-borderColor drop-shadow-lg absolute top-[65px]'}
      >
        <ul
          className={`text-grayTextColor text-sm ${
            isMobile && 'hidden'
          } ${widthValue === '240px' && '!block'}`}
        >
          <li
            className={`text-xs text-[#8d9599] pl-6 pt-5 text-[10px] ${
              widthValue !== '240px' && 'hidden'
            } `}
          >
            MAIN
          </li>
          <li
            style={{ marginTop: !isMobile && widthValue ==='80px' && '10px' }}
            onClick={() => handleNavigate('home')}
            className="flex py-[2px] h-cursor-p p-[18px] group hover:text-[#38cab3] text-md items-center"
          >
            <div
              className={`flex p-3 rounded w-full ${
                selectedMenu == 'dashboard' &&
                'bg-[#f8f6f6] dark:bg-[#383d52] text-[#38cab3]'
              }  `}
            >
              <IconSVG
                className={`group-hover:fill-[#38cab3]  fill-IconsColor ${
                  selectedMenu == 'dashboard' && 'fill-[#38cab3]'
                }`}
                name="dashboardhome"
                fill="#7b8191"
                width={20}
                height={20}
              />
              <span className={textCssText}>
                Dashboard
              </span>
            </div>
          </li>
          <li
            className={`text-[10px] text-[#8d9599] pl-6 pt-[14px] font-poppins ${
              widthValue === '80px' && 'hidden'
            } `}
          >
            WEB APPS
          </li>
          <li
            onClick={() => handleNavigate('inbox')}
            className="flex py-[2px] p-[18px] h-cursor-p group hover:text-[#38cab3] text-md items-center"
          >
            <div
              className={`flex p-3 rounded w-full ${
                selectedMenu == 'inbox' &&
                'bg-[#f8f6f6] dark:bg-[#383d52] text-[#38cab3]'
              }  `}
            >
              <IconSVG
                className={`group-hover:fill-[#38cab3]  fill-IconsColor ${
                  selectedMenu == 'inbox' && 'fill-[#38cab3]'
                } `}
                name="mail"
                fill="#7b8191"
                width={20}
                height={20}
              />
              <span className={textCssText}>
                Inbox
              </span>
            </div>
          </li>
          <li
            onClick={() => handleNavigate('configures')}
            className="flex py-[2px] p-[18px] h-cursor-p group hover:text-[#38cab3]  text-md items-center"
          >
            <div
              className={`flex p-3 rounded w-full ${
                selectedMenu == 'configures' &&
                'bg-[#f8f6f6] dark:bg-[#383d52] text-[#38cab3]'
              }  `}
            >
              <IconSVG
                className={`group-hover:fill-[#38cab3] fill-IconsColor ${
                  selectedMenu == 'configures' && 'fill-[#38cab3]'
                } `}
                name="settings"
                fill="#7b8191"
                width={20}
                height={20}
              />
              <span className={textCssText}>
              Configures
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

LeftMenu.propTypes = {
  selectedMenu: PropTypes.string.isRequired,
};

export default LeftMenu;
