import { CLEAR_USER_ERROR, INIT_USER_SUCCESS, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS, USER_REGISTER_FAILURE, USER_REGISTER_SUCCESS } from '../actionTypes';

const INITIAL_STATE = {};

export default function userReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case INIT_USER_SUCCESS:
  case USER_LOGIN_SUCCESS:
    return {
      ...action.payload
    };
  case USER_LOGIN_FAILURE:
    return {
      error: action.payload
    };
  case USER_REGISTER_SUCCESS:
    return {
      ...action.payload
    };
  case USER_REGISTER_FAILURE:
    return {
      error: action.payload
    };
  case CLEAR_USER_ERROR:
    return {
      error: null
    };
  default:
    return state;
  }
}
