import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './Checkbox';
import Star from './Star';

const Conversation = ({ isRead, setIsMessageOpened, userName, lastMessageDate, message, labels,index, setSelectedMessage }) => {
  return (
    <div className="w-full h-[60px] flex h-cursor-p">
      <div onClick={()=>{setIsMessageOpened(1); setSelectedMessage(index);}}
        className={`hover:drop-shadow-lg  transition-all hover:skew-y-[0.1deg] ${
          isRead ? 'bg-gray-100 dark:bg-bodyColor' : 'bg-cardBackground'
        } flex w-full  border-b border-borderColor`}
      >
        <ul className="w-10/12 flex items-center space-x-1 p-4 ">
          <li className="">
            <Checkbox />
          </li>
          <li className="">
            <Star />
          </li>
          <li className="w-10 centered-items xs:hidden">
            <img
              className="w-8 h-8 rounded-full"
              alt="sas"
              src="https://demos.pixinvent.com/vuexy-vuejs-admin-template/demo-1/assets/avatar-1-129659bb.png"
            />
          </li>
          <li className="text-secondaryTextColor">{userName}</li>
          <li className="text-grayTextColor text-sm w-7/12 xs:hidden ">
            {message}
          </li>
        </ul>
        <ul className="p-4 flex w-2/12 justify-end space-x-3">
          <li className="h-full centered-items space-x-1">
            {labels?.map((label, index) => (
              <div key={index} className={`w-2 h-2 rounded-full bg-blue-400 ${ label[1] }`}>
              </div>
            ))}
          </li>
          <li className="text-xs text-grayTextColor font-poppins centered-items ">
            {lastMessageDate}
          </li>
        </ul>
      </div>
    </div>
  );
};

Conversation.propTypes = {
  isRead: PropTypes.bool,
  setIsMessageOpened: PropTypes.func,
  labels: PropTypes.array,
  setSelectedMessage: PropTypes.func,
  index: PropTypes.number,
  message: PropTypes.string,
  lastMessageDate: PropTypes.string,
  userName: PropTypes.string
};

Conversation.defaultProps = {
  isRead: false,
  setIsMessageOpened: ()=>{}
};

export default Conversation;
