import React from 'react';

import IconSVG from '../../../assets/icons/IconSVG';
import {
  Card,
  CardBody,
  CardHeader,
  CardLeftSide,
  CardRightSide,
} from '../../Card';

const Comments = () => {
  const paragraph =
    'Lorem Ipsum is simply dummy text of the printing asdasdasasdasd asdasdasdasd asdasdasdasd asdasdasdad and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galled it  y of type and scrambley of type and scrambleto make a type specimen book.';
  return (
    <Card additionalClasses="w-full h-full">
      <CardHeader additionalClasses="!text-grayTextColor text-sm font-semibold">
        LAST COMMENTS
      </CardHeader>
      <CardBody className="space-y-4 mt-3">
        <Card additionalClasses="flex drop-shadow-md dark:bg-[#1D1F25] bg-gray-50 border-l-2 border-l-[#0066ff] !p-3">
          <CardBody className="w-full flex">
            <CardLeftSide className="w-11/12">
              <CardHeader additionalClasses="text-xs font-semibold">
                @Alibilmemne
              </CardHeader>
              <p className="text-xs text-grayTextColor w-full line-clamp-2 pr-1">
                {paragraph}
              </p>
            </CardLeftSide>
            <CardRightSide className="w-1/12 flex justify-end items-start">
              <IconSVG name="facebookColorless" className="w-5 h-5" fill="#606472" />
            </CardRightSide>
          </CardBody>
        </Card>
        <Card additionalClasses="flex drop-shadow-md dark:bg-[#1D1F25] bg-gray-50 border-l-2 border-l-red-700 !p-3">
          <CardBody className="w-full flex">
            <CardLeftSide className="w-11/12">
              <CardHeader additionalClasses="text-xs font-semibold">
                @Alibilmemne
              </CardHeader>
              <p className="text-xs text-grayTextColor w-full line-clamp-2 pr-1">
                {paragraph}
              </p>
            </CardLeftSide>
            <CardRightSide className="w-1/12 flex justify-end items-start">
              <IconSVG name="instagramColorless" className="w-5 h-5" fill="#606472" />
            </CardRightSide>
          </CardBody>
        </Card>
        <Card additionalClasses="flex drop-shadow-md dark:bg-[#1D1F25] bg-gray-50 border-l-2 border-l-blue-300 !p-3">
          <CardBody className="w-full flex">
            <CardLeftSide className="w-11/12">
              <CardHeader additionalClasses="text-xs font-semibold">
                @Alibilmemne
              </CardHeader>
              <p className="text-xs text-grayTextColor w-full line-clamp-2 pr-1">
                {paragraph}
              </p>
            </CardLeftSide>
            <CardRightSide className="w-1/12 flex justify-end items-start">
              <IconSVG name="twitterColorless" className="w-5 h-5" fill="#606472" />
            </CardRightSide>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default Comments;
