import { createSelector } from 'reselect';

export const getApp = state => state.app;

export const getAppReady = createSelector([getApp], app => {
  return app.isReady ?? false;
});

// TODO: we should move these selectors to uiSelector
export const getIsModalOpen = createSelector([getApp], app => {
  return app.isModalOpen;
});

export const getIsLeftMenuOpened = createSelector([getApp], app => {
  return app.isLeftMenuOpened;
});

export const getIsMobile = createSelector([getApp], app => {
  return app.isMobile;
});

export const getTheme = createSelector([getApp], app => {
  return app.theme;
});

export const getLeftMenuWidth = createSelector([getIsMobile, getIsLeftMenuOpened], (isMobile, isLeftMenuOpened) => {
  return isMobile ? 0 : isLeftMenuOpened ? 240 : 80;
});

export const getIsUserAuth = createSelector([getApp], app => {
  return app.userAuth ?? false;
});
