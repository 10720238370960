import React from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../../../assets/icons/IconSVG';
import withAnimation from '../../../../hocs/withAnimation';

const item = {
  hidden: {
    opacity: 0,
    translateY: 20
  },
  visible: {
    opacity: 1,
    translateY: 0
  }
};

const animationConfigures = {
  variants: item,
  className: 'min-w-[22%] xs:min-w-[43%] sm:min-w-[44%] md:min-w-[30%] max-w-[165px] max-h-[165px] min-h-[165px] m-2 border dark:border-borderColor border-gray-200 rounded-xl flex flex-col items-center justify-center group/cardDiv h-cursor-p p-2'
};

const SocialMediaCard = ({ socialMediaName, iconName }) => {
  return (
    <>
      <div className="flex justify-center items-center">
        <IconSVG name={iconName} className="rounded-full w-16 h-16" />
      </div>
      <div className="w-full h-2/6 flex justify-center items-center relative">
        <span className='text-sm before:bg-green-500 before:invisible group-hover/cardDiv:before:visible before:w-[80%] before:content-["Connect"] before:py-[6px] before:left-[14px] before:flex before:items-center before:justify-center before:bottom-1 before:rounded-md before:absolute b before:text-white'>
          {socialMediaName}
        </span>
      </div>
    </>
  );
};

SocialMediaCard.propTypes = {
  socialMediaName: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

export default withAnimation( SocialMediaCard, 'div' , animationConfigures) ;
