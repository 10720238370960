import React from 'react';

import IconSVG from '../../assets/icons/IconSVG';

const FirstPage = () => {
  return (
    <div className="w-full flex flex-col centered-items  h-auto p-10 px-5">
      <div className="w-full flex items-center justify-center pb-5 ">
        <IconSVG name={'welcomeImage'} className="w-full" />
      </div>
      <div className="w-full">
        <h1 className="text-lg font-semibold ">
          Manage Online Platforms Posts, Tweets & Analytics
        </h1>
        <p className="text-[#5b5b5b] mt-2 text-xs leading-relaxed">
          Take control of your Social Media with our all-in-one platform. Easily
          manage, analyze, and engage your audience. Schedule bulk sharing,
          create interactive campaigns, and track performance with detailed
          analytics. Seamlessly integrate payments and enjoy the power of
          efficient social media management, all in a single platform.
        </p>
      </div>

    </div>
  );
};

export default FirstPage;
