import React from 'react';
import PropTypes from 'prop-types';

const ModalHeaderContent = ({
  children,
  className,
}) => {
  return (
    <div className={className}>{children}</div>
  );
};

ModalHeaderContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModalHeaderContent.defaultProps = {
  className: '',
};

export default ModalHeaderContent;
