import React from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../assets/icons/IconSVG';

const Button = ({
  name,
  onClick,
  additionalClasses,
  children,
  icon,
  iconSize,
  iconColor,
  iconAndButtonPlacement,
  textClasses,
  type,
}) => {
  return (
    <button type={type} id={name} onClick={onClick} className={`flex items-center ${iconAndButtonPlacement} ${additionalClasses}`}>
      {
        icon ? <IconSVG name={icon} width={iconSize} fill={iconColor} /> : null
      }
      <span className={textClasses}>{children}</span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  additionalClasses: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  iconAndButtonPlacement: PropTypes.string,
  textClasses: PropTypes.string,
};

Button.defaultProps = {
  type: '',
  name: 'buttonRandomly',
  children: 'buttonText',
  onClick: () => { },
  additionalClasses: '',
  icon: '',
  iconColor: '',
  iconAndButtonPlacement: 'justify-center',
  textClasses: '',
};

export default Button;
