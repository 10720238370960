/* eslint-disable no-undef */
import React from 'react';

const Footer = () => {
  return (
    <div className='h-[500px] bg-[#f9f7f7] w-full flex-col flex  items-center sm:h-auto xs:h-auto mt-10'>
      <div className='w-[67%] md:w-full lg:w-full  flex-row flex h-full pt-10 sm:flex-col sm:w-full xs:w-full xs:flex-col'>
        <div className='w-3/12 '><img src={require('../../assets/pngFolder/blacklogo.png')} width={180}></img></div>

        <div className='w-3/12 space-y-5  p-1 text-[#2e2e2e] '>
          <span className='text-lg font-manrope '>Product</span>

          <ul className='space-y-5 text-lg font-light text-black'>
            <li>Landing Pages</li>
            <li>Forms</li>
            <li>Surveys</li>
            <li>Quizzes</li>
            <li>Giveaways</li>
          </ul>

        </div>

        <div className='w-3/12 space-y-5 p-1 '>
          <span className='text-lg font-manrope '>Resources</span>

          <ul className='space-y-5 text-lg font-light text-black'>
            <li>Landing Pages</li>
            <li>Forms</li>
            <li>Surveys</li>
            <li>Quizzes</li>
            <li>Giveaways</li>
          </ul>

        </div>

        <div className='w-3/12 space-y-5 p-1 '>
          <span className='text-lg font-manrope '>Company</span>

          <ul className='space-y-5 text-lg font-light text-black'>
            <li>Landing Pages</li>
            <li>Forms</li>
            <li>Surveys</li>
            <li>Quizzes</li>
            <li>Giveaways</li>
          </ul>

        </div>

        <div className='w-3/12 space-y-5 p-1 '>
          <span className='text-lg font-manrope '>Help</span>

          <ul className='space-y-5 text-lg font-light text-black'>
            <li>Landing Pages</li>
            <li>Forms</li>
            <li>Surveys</li>
            <li>Quizzes</li>
            <li>Giveaways</li>
          </ul>

        </div>
      </div>
      <div className='text-lg font-manrope p-10  centered-items  '>
        © 2023 Sociize. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
