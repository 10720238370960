import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const Modal = ({
  modalKey,
  children,
  onRequestClose,
  className,
  overlayClassName,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc
}) => {
  return (
    <ReactModal
      key={modalKey}
      isOpen={true}
      onRequestClose={onRequestClose}
      className={`w-auto h-auto max-h-[100%] xs:rounded-none bg-bodyBackground p-6 xs:px-2 xs:w-full min-w-[325px] rounded-xl ${className}`}
      overlayClassName={`w-full h-full fixed top-0 left-0 flex items-center justify-center bg-[#282828]/[.6] z-[9999] ${overlayClassName}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  modalKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool
};

Modal.defaultProps = {
  className: '',
  overlayClassName: '',
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: false,
};

export default Modal;