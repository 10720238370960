import { USER_LOGIN_REQUEST, USER_REGISTER_REQUEST } from '../actionTypes';

export const userLoginRequest = payload => {
  return {
    type: USER_LOGIN_REQUEST,
    payload
  };
};

export const userRegisterRequest = payload => {
  return {
    type: USER_REGISTER_REQUEST,
    payload
  };
};
