import { FACEBOOK_LOGIN_REQUEST, FACEBOOK_LOGIN_SUCCESS, SET_SEARCH_INPUT_TEXT, UI_TOGGLE_MODAL } from '../actionTypes';

const INITIAL_STATE = {
  facebookConnection: false,
  searchInputText: '',
};

export default function uiReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FACEBOOK_LOGIN_REQUEST:
    return {
      ...state,
      facebookConnection: 'connecting'
    };
  case FACEBOOK_LOGIN_SUCCESS:
    return {
      ...state,
      facebookConnection: true
    };
  case UI_TOGGLE_MODAL: {
    const { id, value, data } = action.payload;
    return {
      ...state,
      modals: {
        ...state.modals,
        [id]: { value, data }
      }
    };
  }
  case SET_SEARCH_INPUT_TEXT:
    return {
      ...state,
      searchInputText: action.payload
    };
  default:
    return state;
  }
}
