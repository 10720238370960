import React from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../../assets/icons/IconSVG';

const ModalCloseButton = ({
  className,
  onClick,
  disabled,
}) => {
  return (
    <button
      className={`flex items-center justify-center bg-[#ecedfb] dark:bg-bodyColor rounded-full text-[#6f76a7] cursor-pointer h-10 w-10 z-20 p-0 transition ${className}`}
      aria-label="close"
      onClick={onClick}
      disabled={disabled}
    >
      <IconSVG name="bigClose" />
    </button>
  );
};

ModalCloseButton.propTypes = {
  isPositionFixed: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ModalCloseButton.defaultProps = {
  isPositionFixed: false,
  className: '',
  disabled: false,
};

export default ModalCloseButton;
