import axios from '../services/axiosInterceptor';

const getUserByToken = async () => {
  try {
    const response = await axios.get('/auth/user');
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

const userLogin = async (data) => {
  try {
    const response = await axios.post('/auth/login', data);
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

const facebookLogin = async (data) => {
  try {
    const response = await axios.post('/auth/facebook', data);
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

const userRegister = async (data) => {
  try {
    const response = await axios.post('/user/register', data);
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

const authUser = async () => {
  try {
    const response = await axios.post('/auth/user');
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

const api = {
  getUserByToken,
  userLogin,
  facebookLogin,
  userRegister,
  authUser
};

export default api;
