import React from 'react';

import FirstPage from './FirstPage';
import Properties from './Properties';
import Pricing from './Pricing';

const Container = () => {
  return (
    <div className='w-full  h-auto  '>
      <FirstPage/>
      <Properties/>
      <Pricing/>
    </div>
  );
};

export default Container;
