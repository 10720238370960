import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import TopBar from './Navi/TopBar';
import Container from './Container';
import LeftMenu from './LeftMenu';
import { getLeftMenuWidth } from '../../selectors';
import Dashboard from './Dashboard';
import Inbox from './Inbox';
import Configures from './Configures';
import { ErrorPage } from '../ErrorPage';
import ConnectionSettings from './Configures/ConnectionSettings';

const Panel = () => {
  const { componentName = 'home' } = useParams();

  const routes = {
    'home': <Dashboard/>,
    'inbox': <Inbox/>,
    'configures': <Configures/>,
    '404': <ErrorPage/>,
    'connections': <ConnectionSettings/>
  };

  const component = routes[componentName];

  if (!component) {
    return routes['404'];
  }

  useEffect(() => {
    document.title = `Sociize | ${componentName.charAt(0).toUpperCase() + componentName.slice(1)}`;
  }, [componentName]);

  const [calculatedLeftMenuWidth, setCalculatedLeftMenuWidth] = useState(0);
  const leftMenuWidth = useSelector(getLeftMenuWidth);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setCalculatedLeftMenuWidth(windowWidth - leftMenuWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [leftMenuWidth]);

  return (
    <div className="w-full h-screen overflow-y-auto bg-bodyColor">
      <div className="flex w-full h-auto min-h-[100vh]">
        <LeftMenu selectedMenu={componentName} />
        <div style={{ width: calculatedLeftMenuWidth }} className="flex flex-col">
          <TopBar />
          <Container component={component} />
        </div>
      </div>
    </div>
  );
};

Panel.propTypes = {
  component: PropTypes.node,
  componentName: PropTypes.string
};

export default Panel;
