import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getSearchInputText } from '../../selectors';

const Highlight = ({ text }) => {
  const searchInputText = useSelector(getSearchInputText);
  if (searchInputText.length === 0 || searchInputText.trim() === '') {
    return text;
  }

  const regex = new RegExp(`(${searchInputText})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, i) => (
    <span
      key={i}
      className={part.toLowerCase() === searchInputText.toLowerCase() ? 'bg-yellow-200' : ''}
    >
      {part}
    </span>
  ));
};

Highlight.propTypes = {
  text: PropTypes.string.isRequired
};

export default React.memo(Highlight);
