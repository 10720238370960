import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ component }) => {
  return (
    <div className="bg-bodyColor h-auto px-10 py-6 w-full xs:px-5 md:px-3 sm:px-3">
      {
        component
      }
    </div>
  );
};

Container.propTypes = {
  component: PropTypes.object.isRequired
};

export default Container;
