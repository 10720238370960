/* eslint-disable no-undef */
import React from 'react';

import IconSVG from '../../../assets/icons/IconSVG';
import {
  Card,
  CardBody,
  CardHeader,
  CardLeftSide,
  CardRightSide,
} from '../../Card';

const UserCards = () => {
  const userData = require('./userData.json');
  return (
    <div className="grid grid-cols-2 w-full gap-4 h-auto mt-5">
      {userData.map((data, index) => (
        <Card
          key={index}
          additionalClasses="flex w-full h-[120px] drop-shadow-sm sm:pt-0 sm:items-center !pb-0 xs:p-0 "
        >
          <CardLeftSide className="w-9/12 xs:flex pl-2 xs:flex-col xs:justify-center xs:items-center xs:p-0">
            <CardHeader additionalClasses="text-sm sm:text-xs xs:text-xs">
              {data.firstTitle}
            </CardHeader>
            <CardBody className="w-full sm:w-full xs:w-full flex flex-col xs:justify-center xs:items-center">
              <span className="text-primaryTextColor text-xl w-full flex flex-col xs:items-center font-semibold">
                {data.total}
              </span>
              <div className="flex items-center space-x-2 mt-[6px] sm:space-x-1 xs:mt-2 xs:justify-center xs:space-x-[2px]">
                <span className="text-xs text-grayTextColor">Last week</span>
                <IconSVG
                  className="w-[10px]"
                  name={data.isPositive ? 'upArrow' : 'downArrow'}
                  fill={data.isPositive ? '#38cab3' : '#f34343'}
                />
                <span
                  className={`text-xs ${
                    data.isPositive ? 'text-[#38cab3]' : 'text-[#f34343]'
                  }`}
                >
                    +427
                </span>
              </div>
            </CardBody>
          </CardLeftSide>
          <CardRightSide className="w-3/12 -mt-5 h-full sm:mt-0 xs:mt-0">
            <CardBody className="w-full h-full items-center justify-center flex p-2 xs:p-0">
              <div
                style={{ backgroundColor: data.bgColor }}
                className="min-w-[45px] min-h-[45px] rounded xs:rounded-l-none centered-items xs:w-full xs:h-full"
              >
                <IconSVG
                  name={data.icon}
                  fill={data.color}
                  className="xs:w-5 xs:h-5"
                />
              </div>
            </CardBody>
          </CardRightSide>
        </Card>
      ))}
    </div>
  );
};

export default UserCards;
