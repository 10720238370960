import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={className} {...props}>{children}</div>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModalHeader.defaultProps = {
  className: '',
};

export default ModalHeader;
