import { combineReducers } from 'redux';

import app from './appReducer';
import ui from './uiReducer';
import social from './socialReducer';
import user from './userReducer';

const reducers = {
  app,
  ui,
  social,
  user,
};

export default combineReducers(reducers);
