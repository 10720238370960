import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../Input';
import Button from '../../Button';
import { userLoginRequest } from '../../../actions/userActions';
import { getUser } from '../../../selectors';
import { clearUserError as clearUserErrorAction } from '../../../actions/uiActions';

const Login = ({ setUserGatePage }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const clearUserError = () =>{
    dispatch(clearUserErrorAction());
  };

  const [authCredentials, setAuthCredentials] = useState({
    text: '',
    password: '',
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (authCredentials.text !== '' && authCredentials.password !== '') {
      setError(false);
    }
  }, [authCredentials]);

  useEffect(() => {
    if (user.error) {
      setError(true);
    }

    if (user.token) {
      window.location.href = '/dashboard';
    }

    return () => {};
  }, [user]);

  const handleAuthCredentialsChange = (e) => {
    if (error) {
      setError(false);
    }

    setAuthCredentials({
      ...authCredentials,
      [e.target.type]: e.target.value,
    });
  };

  const handleNavigation = () => {
    setUserGatePage('register');
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (authCredentials.text === '' || authCredentials.password === '') {
      setError(true);
    } else {
      dispatch(userLoginRequest({
        email: authCredentials.text,
        password: authCredentials.password
      }));
    }
  };

  return (
    <div className="w-full h-auto flex flex-col items-center xs:px-2">
      <div className="w-full flex-col flex items-center mb-10">
        <span className="text-2xl font-poppins font-semibold text-primaryTextColor">
          Log in your account!
        </span>
        <span className="text-sm text-grayTextColor mt-3">
          Manage your social accounts as a professional.
        </span>
      </div>
      <form onSubmit={handleLogin}>
        <Input
          title="Username or E-mail"
          type="text"
          onChange={handleAuthCredentialsChange}
          inputAdditionalClasses="active:ring-slate-400 outline-blue-300 focus-within:outline-5"
        />
        <Input
          title="Password"
          type="password"
          additionalClasses="relative"
          inputAdditionalClasses="active:ring-slate-400 outline-blue-300 focus-within:outline-5"
          isPassword={true}
          onChange={handleAuthCredentialsChange}
        />
        <div className="w-full flex justify-start">
          {error && (
            <span className="text-red-500 text-sm font-semibold">
            Invalid credentials.
            </span>
          )}
        </div>
        <Button
          type="submit"
          additionalClasses="h-14 w-full text-white bg-[#38cab3] mt-4 hover:bg-[#5cd3b9] centered-items rounded font-bold"
        >
        Login
        </Button>
      </form>
      <div className="w-full centered-items text-sm mt-5 text-primaryTextColor font-semibold ">
        Don&#39;t have an account?
        {
          setUserGatePage ? <span onClick={handleNavigation} className="ml-2 text-[#38cab3] h-cursor-p">Sign up</span>
            : <Link onClick={clearUserError} className="ml-2 text-[#38cab3] h-cursor-p" to={'/register'}>Sign up</Link>
        }
      </div>
    </div>
  );
};

Login.propTypes = {
  setUserGatePage: PropTypes.func,
};

Login.defaultProps = {
  setUserGatePage: false,
};

export default Login;
