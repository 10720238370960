import React from 'react';

import ModalLayout from '../common/Modal/ModalLayout';
import { CONNECTION_SETTINGS_MODAL } from '../../constants/Modals';
import ConnectionSettings from '../Panel/Configures/ConnectionSettings';

const ConnectionSettingsModal = () => {
  return (
    <ModalLayout
      modalKey={CONNECTION_SETTINGS_MODAL}
      modalHeaderContentClassNames={'flex justify-between items-center'}
      title={<span className="text-primaryTextColor text-2xl font-semibold">Manage Your Connections</span>}
    >
      <ConnectionSettings />
    </ModalLayout>
  );
};

export default ConnectionSettingsModal;
