import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Search from './Search';
import Conversation from './Conversation';
import IconSVG from '../../../assets/icons/IconSVG';
import MessageWindow from './MessageWindow';

const RightPanel = ({ isLeftPanelOpened, setIsLeftPanelOpened, messages }) => {
  const [isMessageOpened, setIsMessageOpened] = useState(-1);
  const [selectedMessage, setSelectedMessage] = useState(-1);

  return (
    <div className='h-auto bg-cardBackground border-l-[1px] border-borderColor flex w-full z-50 md:rounded-l-lg sm:rounded-l-lg xs:rounded-l-lg'>
      <MessageWindow message={messages[selectedMessage]} setIsMessageOpened={setIsMessageOpened} isMessageOpened={isMessageOpened}/>
      <div className='w-full bg-bodyBackground md:rounded-l-lg sm:rounded-l-lg xs:rounded-l-lg'>
        <div className='flex items-center border-b h-cursor-p h-[60px] border-borderColor'><span onClick={()=>setIsLeftPanelOpened(!isLeftPanelOpened)} className='h-full p-3 h-cursor-p lg:hidden xl:hidden 2xl:hidden '><IconSVG name='menuToggle'/> </span><Search/></div>
        {messages.map(( message, index ) => (
          <Conversation
            index={index}
            setSelectedMessage={setSelectedMessage}
            labels={message.labels}
            key={index}
            userName={message.userName}
            message={message.message}
            lastMessageDate={message.lastMessageDate}
            isRead={message.isRead}
            setIsMessageOpened={setIsMessageOpened}
          />
        ))}
      </div>

    </div>
  );
};

RightPanel.propTypes = {
  isLeftPanelOpened: PropTypes.bool,
  setIsLeftPanelOpened: PropTypes.func,
  messages: PropTypes.array
};

export default RightPanel;
