import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { getIsUserAuth } from '../selectors';
import ErrorBoundary from '../components/ErrorBoundary';

const ProtectedRoutes = () => {
  const isAuthenticated = useSelector(getIsUserAuth);

  return (
    isAuthenticated ? <ErrorBoundary> <Outlet /> </ErrorBoundary> : <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
