import React, { useState } from 'react';

import IconSVG from '../../../assets/icons/IconSVG';

const Star = () => {
  const [isImportant, setIsImportant] = useState(false);
  return (
    <div className="w-10 h-10 centered-items z-50 select-none">
      <div
        onClick={() => setIsImportant(!isImportant)}
        className={`w-9 h-9 rounded-full h-cursor-p hover:bg-[#00021f07] dark:hover:bg-gray-600 centered-items  transition-all ${
          isImportant
            ? 'active:bg-[#f3b12e9e] dark:active:bg-[#f3b12e9e]'
            : 'active:bg-[#00021f14] dark:active:bg-[#00021f72]'
        }`}
      >
        <IconSVG
          name={isImportant ? 'starFill' : 'starBorder'}
          fill={isImportant ? 'orange':'gray'}
          width={24}
          height={24}
        />
      </div>
    </div>
  );
};

export default Star;
