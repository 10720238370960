/* eslint-disable no-undef */
import React from 'react';

const Analytics = () => {
  return (
    <div className='w-full h-[59vh] bg-[#f9f7f7] flex sm:flex-col sm:h-auto sm:p-10 '>
      <div className='w-6/12 flex justify-end sm:w-full md:justify-end lg:w-6/12 lg:justify-center'>
        <div className='w-8/12 centered-items sm:w-full md:w-10/12 lg:w-10/12 '>
          <img src={require('../../assets/pngFolder/analystic.png')} />
        </div>
      </div>
      <div className='w-[34%] flex flex-col h-full md:w-6/12 sm:w-full lg:w-6/12 md:items-center  items-center'>
        <div className='w-11/12 h-full flex  items-center font-manrope sm:w-full sm:p-0 md:w-full md:p-8 lg:w-10/12'>
          <ul className='space-y-9'>
            <li className='text-lg text-gray-500 '>Analytics</li>
            <li className='text-4xl font-semibold lg:text-3xl md:text-3xl'>Get the insights you need to guess less & grow more</li>
            <li>
              <p className='text-lg text-ligth text-gray-500 lg:text-base md:text-base '>Track the performance of your campaign with easy to understand tracking data. Get real time resuyourlts for  landing pages, quizzes, surveys, polls or sales. No need to dig around for campaign performance – it’s all right at your fingertips.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
