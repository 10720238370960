import React from 'react';

import InboxPanel from './InboxPanel';
import withAnimation from '../../../hocs/withAnimation';

const Inbox = () => {
  return (
    <>
      <span className="text-primaryTextColor font-semibold text-lg">
        INBOX
      </span>
      <div className="w-full h-auto flex justify-center">

        <InboxPanel/>
      </div>

    </>
  );
};

const animationConfigures = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  className: 'h-full'
};

export default withAnimation(Inbox, 'div', animationConfigures);
