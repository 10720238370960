import { createSelector } from 'reselect';

export const getUser = state => state.user;

export const getUserID = createSelector(
  getUser,
  user => user.id
);

export const getUserFullName = createSelector(
  getUser,
  user => user.fullName
);

export const getUserEmail = createSelector(
  getUser,
  user => user.email
);

export const getUserPicture = createSelector(
  getUser,
  user => user.avatar
);

export const getUserPlan = createSelector(
  getUser,
  user => user.plan
);

export const getUserRole = createSelector(
  getUser,
  user => user.role
);

export const getIsUserLoggedIn = createSelector(
  getUser,
  user => user.isLoggedIn === true
);
