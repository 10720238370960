import React from 'react';
import PropTypes from 'prop-types';

const ModalBody = ({
  children,
  className,
  ...props
}) => {
  return (
    <section className={className} {...props}>{children}</section>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModalBody.defaultProps = {
  className: '',
};

export default ModalBody;