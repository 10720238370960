import React from 'react';
import PropTypes from 'prop-types';

const CardBody = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

CardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardBody.defaultProps = {
  className: '',
};

export default CardBody;
