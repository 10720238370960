import React from 'react';

import Pricing from './Pricing';
import Footer from './Footer';
import Header from './Header';
import FirstPage from './FirstPage';
import Properties from './Properties';
import Analytics from './Analytics';
import Social from './Social';

const index = () => {
  return (
    <div className='h-auto '>
      <Header/>
      <FirstPage/>
      <Properties/>
      <Pricing/>
      <Analytics/>
      <Social/>
      <Footer/>
    </div>
  );
};

export default index;
