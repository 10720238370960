import React, { useState } from 'react';

import Login from '../Landing/Login/Login';
import Register from '../Landing/Register/Register';

const UserGate = () => {
  const [userGatePage, setUserGatePage] = useState('login');
  const menuItems = {
    register: <Register setUserGatePage={setUserGatePage}/>,
    login: <Login setUserGatePage={setUserGatePage} />,
  };
  return (
    <div className={'overflow-x-hidden overflow-y-auto rounded-xl flex flex-col items-center justify-center xs:rounded-none'}>
      <div className="h-auto w-auto py-5 px-10 xs:px-0">
        {menuItems[userGatePage]}
      </div>
    </div>
  );
};

export default UserGate;
