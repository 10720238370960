import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ children, additionalClasses }) => {
  return (
    <div className={`bg-cardBackground rounded drop-shadow-sm p-5 ${additionalClasses}`}>{children}</div>
  );
};
Card.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string,
};

Card.defaultProps = {
  additionalClasses: '',
};

export default Card;
