/* eslint-disable no-undef */
import React from 'react';

import IconSVG from '../../assets/icons/IconSVG';

const Pricing = () => {
  const offerList = require('./pricingSettings.json');
  return (
    <div  id="pricing-component" className="w-full h-auto min-h-screen bg-white flex justify-center  ">
      <div className="p-10 mt-24 w-[100wv] sm:p-0 justify-center flex-col ">
        <div className="centered-items">
          <h1 className="text-3xl font-semibold mb-10 text-center">
            Choose the best plan for your business
          </h1>
        </div>
        <div className="w-auto centered-items grid grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mb-10 gap-4 justify-center">
          {offerList.map((e, index) => (
            <div
              key={index}
              className={`w-[18%] lg:w-[30%] min-w-[340px] max-w-[400px] h-auto ${index === 0 ? ' xl:rounded-l-xl ' : index === 3 && 'xl:rounded-r-xl border-r  '} border-b border-l border-t lg:border md:border sm:border border-gray-400 mt-4 pt-2 pb-8 ${
                e.isMostPopular ? 'bg-orange-100' : e.isRecommended && 'bg-blue-100'
              } `}
            >
              <div className="w-full centered-items">
                <div className="block  space-y-2 w-full px-4">
                  <div
                    className={`w-full centered-items ${
                      !e.isMostPopular && !e.isRecommended && 'invisible'
                    } `}
                  >
                    <span className={`p-1 h-8 rounded-full text-sm centered-items bg-[#ff4400] ${e.isRecommended && 'bg-blue-500'} text-white `}>
                      {e.isMostPopular ? 'Most Popular' : e.isRecommended && 'Recommended'}
                    </span>
                  </div>
                  <span className="font-semibold text-xl  centered-items w-full ">
                    {e.mainTitle}
                  </span>
                  <span className="font-semibold w-full centered-items text-4xl mt-2">
                    {e.Price}
                  </span>
                  <p className="text-xs w-full  text-center">
                    {e.subTitle}
                  </p>
                  <div className="centered-items ">
                    <div className="w-4/6 bg-[#ff4400] rounded-md mb-2 p-3 centered-items">
                      <span className="text-white">Buy It</span>
                    </div>
                  </div>
                  <div className="w-full  border-t border-t-orange-400 mt-2 space-y-6 ">
                    {
                      e.attributes.map((attribute, index)=>(
                        <div key={index} className={`flex items-center mt-2 ${!attribute.isHave && 'opacity-30'} text-sm space-x-2`}>
                          <IconSVG name="check" fill={attribute.isHave ? '#0ebb63' : 'gray'} className="" />
                          <span>{attribute.attributeName}</span>
                        </div>
                      ))
                    }

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
