import React from 'react';

import IconSVG from '../../assets/icons/IconSVG';

const Properties = () => {
  return (
    <div className="w-full h-auto mt-5  bg-[#f9f7f7] px-5 py-10">
      <h1 className="text-lg text-center font-semibold block">
        Manage Your social media accounts effortlessly
      </h1>
      <h1 className="text-xs text-center text-[#5b5b5b] centered-items ">
        An all-in-one social media management platform that empowers you to
        create diverse and engaging campaigns.
      </h1>
      <div className="grid grid-cols-2 gap-2 mt-5">
        <div className="border border-b-4 rounded-lg h-auto p-4">
          <IconSVG name="schedule" fill="#ff4400" />
          <span className="text-sm font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-xs font-normal leading-relaxed">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
        <div className="border border-b-4 rounded-lg h-auto  p-4 ">
          <IconSVG name="schedule" fill="#ff4400" />
          <span className="text-sm font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-xs font-normal leading-relaxed">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div> <div className="border border-b-4 rounded-lg h-auto p-4">
          <IconSVG name="schedule" fill="#ff4400" />
          <span className="text-sm font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-xs font-normal leading-relaxed">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div> <div className="border border-b-4 rounded-lg h-auto p-4">
          <IconSVG name="schedule" fill="#ff4400" />
          <span className="text-sm font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-xs font-normal leading-relaxed">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
      </div>
    </div>
  );
};

export default Properties;
