import React from 'react';
import { useDispatch } from 'react-redux';

import UserCards from './UserCards';
import { toggleModal } from '../../../actions';
import { USER_GATE_MODAL } from '../../../constants/Modals';
import Button from '../../Button';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardLeftSide,
  CardRightSide,
} from '../../Card';

const WelcomeCard = () => {
  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(
      toggleModal({
        id: USER_GATE_MODAL,
        value: true,
      })
    );
  };
  return (
    <>
      <Card additionalClasses="w-full">
        <CardHeader additionalClasses="text-[1.5rem] sm:text-lg xs:text-[14px] tracking-wider font-medium">
          Hi, Welcome User!
        </CardHeader>
        <CardBody className="flex">
          <CardLeftSide className="w-8/12">
            <CardBody>
              <p className="text-sm text-grayTextColor sm:text-xs xs:text-[12px]">
                Yeni deneyimlere açılan kapınızı aralayın, paylaşım gücünüzü
                keşfedin ve etkileyici izler bırakın!
              </p>
            </CardBody>
            <CardFooter>
              <Button
                onClick={onClickHandler}
                icon="plus"
                iconColor="white"
                iconSize={15}
                additionalClasses="w-[125px] h-[40px] bg-[#38cab3] mt-4 hover:bg-[#5cd3b9] h-cursor-p rounded  drop-shadow-md text-white text-sm space-x-1 px-2"
              >
                Create A Post
              </Button>
            </CardFooter>
          </CardLeftSide>
          <CardRightSide className="w-4/12"></CardRightSide>
        </CardBody>
      </Card>
      <UserCards />
    </>
  );
};

export default WelcomeCard;
