/* eslint-disable no-undef */
import React from 'react';

const Footer = () => {
  return (
    <div className="h-auto w-full bottom-0 bg-[#f9f7f7] pt-5 pl-10 pb-2">
      <div className="">
        <img
          className="-ml-2"
          src={require('../../assets/pngFolder/blacklogo.png')}
          width={150}
        />
      </div>
      <div className="pb-2 ">

        <ul className="flex font-semibold">
          <li className="">Product</li>
          <li className="ml-5">Resources</li>
          <li className="ml-5">Company</li>
          <li className="ml-5">Help</li>

        </ul>
      </div>
      <div className="text-sm font-manrope ">
        <span>© 2023 Sociize. All rights reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
