import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { getIsUserAuth } from '../selectors';

const UnprotectedRoutes = () => {
  const isAuthenticated = useSelector(getIsUserAuth);

  return (
    !isAuthenticated ? <Outlet /> : <Navigate to="/panel" />
  );
};

export default UnprotectedRoutes;
