import React from 'react';

import IconSVG from '../../../assets/icons/IconSVG';

const Search = () => {
  return (
    <div className="w-full h-[60px]">
      <div className="w-full flex h-full">
        <div className="w-fit h-full centered-items pl-4">
          <IconSVG
            name="search"
            className="fill-IconsColor"
            width={20}
            height={20}
          />
        </div>
        <div className="w-full h-full ">
          <input placeholder="Search Message" className="w-full bg-cardBackground font-poppins text-sm text-grayTextColor  h-full align-bottom outline-none px-2"></input>
        </div>
      </div>
    </div>
  );
};

export default Search;
