import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import IconSVG from '../../assets/icons/IconSVG';
import { setSearchInputText as setSearchInputTextAction } from '../../actions';
import { getSearchInputText } from '../../selectors';

const SearchInput = () => {
  const dispatch = useDispatch();
  const searchInputText = useSelector(getSearchInputText);

  const [inputValue, setInputValue] = useState(searchInputText);

  // Create a delayed dispatch function using debounce
  const delayedDispatch = useCallback(
    debounce((text) => {
      dispatch(setSearchInputTextAction(text));
    }, 1000),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setInputValue(text);

    // Call the debounce function
    delayedDispatch(text);
  };

  return (
    <div className="w-60 h-11 border border-borderColor items-center justify-between focus:border-red-400 flex rounded bg-bodyBackground px-2 xs:w-[50%] xs:text-xs">
      <IconSVG
        name="search"
        fill="#00000023"
        width={25}
        height={25}
        className="dark:fill-gray-600"
      />
      <input
        value={inputValue}
        onChange={handleSearchInputChange}
        placeholder="Search in your accounts"
        className="w-[90%] h-full p-2 group/searchText bg-bodyBackground text-secondaryTextColor focus:outline-none truncate"
      />
    </div>
  );
};

export default SearchInput;
