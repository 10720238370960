import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../../assets/icons/IconSVG';

const LeftPanel = ({ setIsLeftPanelOpened,labelNames, setLabelNames }) => {
  const [selectedMenu, setSelectedMenu] = useState('all-messages-li');

  const [isLabelAdjustmentOpened,setIsLabelAdjustmentOpened] = useState(false);

  const setLabel = (e) => {
    if (e.target.value !== null || e.target.value !== '') {
      setLabelNames({ ...labelNames, [e.target.id]: e.target.value });
    }
  };

  const handlerSelectionMenu = (id) =>{
    if (selectedMenu !== id) {
      setSelectedMenu(id);
      setIsLeftPanelOpened(false);
    }
  };

  return (
    <div className='h-full bg-cardBackground transition-all rounded-l-lg'>
      <ul className='text-sm transition-all'>
        <li onClick={()=>handlerSelectionMenu('all-messages-li')} className='p-3 flex border-b mb-1 h-cursor-p h-[60px] border-borderColor'>
          <div className="flex items-center min-w-fit w-full">
            <div className="w-5">
              <IconSVG name="mailBox" className={`fill-secondaryTextColor ${selectedMenu == 'all-messages-li' && 'fill-green-600'} `} />
            </div>
            <span className="ml-2 text-base  text-secondaryTextColor font-poppins">
              All Messages
            </span>
          </div>
          <div className="w-full flex items-center justify-end">
            <div className="w-6 h-5 bg-[#ff780a5e] rounded-full centered-items">
              <span className="text-xs text-orange-400 font-poppins">9</span>
            </div>

          </div>
        </li>
        <li id='facebook-li' onClick={()=>handlerSelectionMenu('facebook-li')} className={`select-none h-cursor-p border-l-[3px] ${selectedMenu == 'facebook-li' && '!border-[#38cab3]  dark:bg-gray-700 bg-gray-50' } border-bodyBackground `}>
          <div className='flex p-3'>
            <div className="flex items-center min-w-fit ">
              <div className="w-5">
                <IconSVG
                  name="facebookColorless"
                  className="fill-secondaryTextColor"
                />
              </div>
              <span className="ml-2 text-secondaryTextColor font-poppins">
              Facebook
              </span>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-6 h-5 bg-[#2e7af344] rounded-full centered-items">
                <span className="text-xs text-[#38cab3] font-poppins">9</span>
              </div>

            </div>
          </div>
        </li>
        <li id='twitter-li' onClick={()=>handlerSelectionMenu('twitter-li')} className={`select-none h-cursor-p border-l-[3px] ${selectedMenu == 'twitter-li' && '!border-[#38cab3]  dark:bg-gray-700 bg-gray-50' } border-bodyBackground `}>
          <div className='p-3 flex'>
            <div className="flex items-center min-w-fit">
              <div className="w-5 ">
                <IconSVG
                  name="twitterColorless"
                  className="fill-secondaryTextColor"
                />
              </div>
              <span className="ml-2 text-secondaryTextColor font-poppins">
              Twitter
              </span>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-6 h-5 bg-[#18f3f32d] rounded-full centered-items">
                <span className="text-xs text-[#19d1d1ed] font-poppins">9</span>
              </div>

            </div>
          </div>
        </li>
        <li id='instagram-li' onClick={()=>handlerSelectionMenu('instagram-li')} className={`select-none h-cursor-p border-l-[3px] ${selectedMenu == 'instagram-li' && '!border-[#38cab3]  dark:bg-gray-700 bg-gray-50' } border-bodyBackground `}>
          <div className='p-3 flex'>
            <div className="flex items-center min-w-fit">
              <div className="w-5">
                <IconSVG
                  name="instagramColorless"
                  className="fill-secondaryTextColor"
                />
              </div>
              <span className="ml-2 text-secondaryTextColor font-poppins">
              Instagram
              </span>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-6 h-5 bg-[#f30c1835] rounded-full centered-items">
                <span className="text-xs text-red-400 font-poppins">9</span>
              </div>

            </div>
          </div>
        </li>
        <li id='linkedin-li' onClick={()=>handlerSelectionMenu('linkedin-li')} className={`select-none h-cursor-p border-l-[3px] ${selectedMenu == 'linkedin-li' && '!border-[#38cab3]  dark:bg-gray-700 bg-gray-50' } border-bodyBackground `}>
          <div className='p-3 flex'>
            <div className="flex items-center min-w-fit">
              <div className="w-5">
                <IconSVG
                  name="linkedinColorless"
                  className="fill-secondaryTextColor"
                />
              </div>
              <span className="ml-2 text-secondaryTextColor font-poppins">
              Linkedin
              </span>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-6 h-5 bg-[#0f97ff49] rounded-full centered-items">
                <span className="text-xs text-[#38cab3] font-poppins">9</span>
              </div>

            </div>
          </div>
        </li>
        <li id='starred-li' onClick={()=>handlerSelectionMenu('starred-li')} className={`select-none h-cursor-p border-l-[3px] ${selectedMenu == 'starred-li' && '!border-[#38cab3]  dark:bg-gray-700 bg-gray-50' } border-bodyBackground `}>
          <div className='flex p-3'>
            <div className="flex items-center min-w-fit">
              <div className="w-5">
                <IconSVG
                  name="starFill"
                  className="fill-secondaryTextColor"
                />
              </div>
              <span className="ml-2 text-secondaryTextColor font-poppins">
              Starred
              </span>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-6 h-5 bg-orange-400 rounded-full centered-items">
                <span className="text-xs text-white font-poppins">9</span>
              </div>

            </div>
          </div>
        </li>
        <li id='trash-li' onClick={()=>handlerSelectionMenu('trash-li')} className={`select-none h-cursor-p border-l-[3px] ${selectedMenu == 'trash-li' && '!border-[#38cab3]  dark:bg-gray-700 bg-gray-50' } border-bodyBackground `}>
          <div className='flex p-3'>
            <div className="flex items-center min-w-fit">
              <div className="w-5">
                <IconSVG
                  name="trash"
                  className="fill-secondaryTextColor"
                />
              </div>
              <span className="ml-2 text-secondaryTextColor font-poppins">
              Trash
              </span>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-6 h-5 bg-red-400 rounded-full centered-items">
                <span className="text-xs text-white font-poppins">9</span>
              </div>

            </div>
          </div>
        </li>
        <li>
          <div className='w-full py-2 border-b border-borderColor'></div>
        </li>
        <li className="p-3 flex">
          <div className='flex justify-between w-full'>
            <span className='text-primaryTextColor text-lg'>Labels</span>
            <span onClick={()=>{ setIsLabelAdjustmentOpened(!isLabelAdjustmentOpened); }} className='text-primaryTextColor text-lg active:bg-gray-100 dark:active:bg-gray-700 rounded-full h-7 w-7 centered-items h-cursor-p active:rotate-180 transition-all delay-[10]'>
              {
                isLabelAdjustmentOpened ? <IconSVG name='checkType2' /> : <IconSVG name='settings' />
              }
            </span>
          </div>
        </li>
        <li className="p-3 flex items-center space-x-4 text-md text-secondaryTextColor">
          <div className="w-2 h-2 rounded-full bg-green-400"></div>
          <span className=''>
            {
              isLabelAdjustmentOpened ? <input onChange={setLabel}  id='personal-label' placeholder={ labelNames['personal-label'] } className={'h-6 w-[60%] outline-none border border-borderColor bg-bodyBackground pl-2'}/> : labelNames['personal-label']
            }
          </span>
        </li>
        <li className="p-3 flex items-center space-x-4 text-md text-secondaryTextColor">
          <div className="w-2 h-2 rounded-full bg-[#7864f4]"></div>
          <span>
            {
              isLabelAdjustmentOpened ? <input onChange={setLabel} id='company-label' placeholder={labelNames['company-label']} className={'h-6 w-[60%] outline-none border border-borderColor bg-bodyBackground pl-2'}/> : labelNames['company-label']
            }</span>
        </li>
        <li className="p-3 flex items-center space-x-4 text-md text-secondaryTextColor">
          <div className="w-2 h-2 rounded-full bg-orange-600"></div>
          <span>
            {
              isLabelAdjustmentOpened ? <input onChange={setLabel} id='important-label' placeholder={labelNames['important-label']} className={'h-6 w-[60%] outline-none border border-borderColor bg-bodyBackground pl-2'}/> : labelNames['important-label']
            }</span>
        </li>
        <li className="p-3 flex items-center space-x-4 text-md text-secondaryTextColor">
          <div className="w-2 h-2 rounded-full bg-red-500"></div>
          <span>
            {
              isLabelAdjustmentOpened ? <input onChange={setLabel} id='private-label' placeholder={labelNames['private-label']} className={'h-6 w-[60%] outline-none border border-borderColor bg-bodyBackground pl-2'}/> : labelNames['private-label']
            }</span>
        </li>
      </ul>
    </div>
  );
};

LeftPanel.propTypes = {
  isLeftPanelOpened: PropTypes.bool.isRequired,
  setIsLeftPanelOpened: PropTypes.func.isRequired,
  setLabelNames: PropTypes.func,
  labelNames: PropTypes.object
};

export default LeftPanel;
