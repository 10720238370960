import { call, put, takeEvery } from 'redux-saga/effects';

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_REGISTER_REQUEST,
  USER_REGISTER_FAILURE,
  INIT_USER_REQUEST,
  INIT_USER_FAILURE,
  INIT_USER_SUCCESS,
  USER_REGISTER_SUCCESS,
} from '../actionTypes';
import api from '../api';
import { getData, getResponseCode } from '../api/utils';

function* initUser () {
  try {
    const user = getData(yield call(api.getUserByToken));

    if (!user._id) {
      throw new Error('User is not found');
    }

    user.id = user._id;
    delete user._id;

    yield put({
      type: INIT_USER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    yield put({
      type: INIT_USER_FAILURE,
      error: error.message ?? 'Error in init user',
    });
  }
}

function* userLogin (action) {
  try {
    const { email, password } = action.payload;
    const response = yield api.userLogin({ email, password });
    const data = getData(response);

    sessionStorage.setItem('user', JSON.stringify(data.user));
    sessionStorage.setItem('token', JSON.stringify(data.token));

    yield put({
      type: USER_LOGIN_SUCCESS,
      payload: {
        ...data.user,
        token: data.token,
        expiresIn: data.expiresIn,
      },
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: USER_LOGIN_FAILURE,
      payload: 'Invalid email or password',
    });
  }
}

function* userRegister (action) {
  try {
    const { email, password, fullName } = action.payload;
    const response = yield api.userRegister({ email, password, fullName });
    const responseStatus = getResponseCode(response);

    if (responseStatus !== 200) {
      throw new Error(JSON.stringify(responseStatus));
    }
    const user = getData(response);
    yield put({
      type: USER_REGISTER_SUCCESS,
      payload: user,
    });
  } catch (err) {
    yield put({
      type: USER_REGISTER_FAILURE,
      payload: err.message ?? 'Error in register 93',
    });
  }
}

const socialSaga = [
  takeEvery(INIT_USER_REQUEST, initUser),
  takeEvery(USER_LOGIN_REQUEST, userLogin),
  takeEvery(USER_REGISTER_REQUEST,userRegister)
];

export default socialSaga;
