import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { facebookLoginRequest as facebookLoginRequestAction } from '../actions';
import { getIsFacebookConnected } from '../selectors';

const FBLoginv2 = () => {
  const dispatch = useDispatch();
  const isFacebookConnected = useSelector(getIsFacebookConnected);

  const handleLogin = () => {
    window.FB.init({
      appId: '1020616122473431',
      cookie: true,
      xfbml: true,
      version: 'v11.0'
    });

    const scope = 'user_birthday, user_hometown, user_location, user_likes, user_events, user_payment_tokens, user_photos, user_videos, user_friends, user_posts, user_gender, user_age_range, email, read_insights, publish_video, catalog_management, private_computation_access, user_managed_groups, groups_show_list, pages_manage_cta, pages_manage_instant_articles, pages_show_list, read_page_mailboxes, ads_management, ads_read, business_management, pages_messaging, pages_messaging_phone_number, pages_messaging_subscriptions, publish_to_groups, groups_access_member_info, leads_retrieval, instagram_manage_messages, attribution_read, page_events, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_ads, pages_manage_posts, pages_manage_engagement, public_profile';

    window.FB.login(response => {
      if (response.authResponse) {
        dispatch(facebookLoginRequestAction(response));
      } else {
        // TODO: handle error
      }
    }, { scope });
  };

  return (
    <>
      {
        isFacebookConnected
          ? (<button onClick={() => {}}>Logout from Facebook</button>)
          : (<button onClick={handleLogin}>Login with Facebook</button>)
      }
    </>
  );
};

export default FBLoginv2;
