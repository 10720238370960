import React from 'react';

import IconSVG from '../../assets/icons/IconSVG';

const Properties = () => {
  return (
    <div className="w-full min-w-full h-auto min-h-screen bg-[#f9f7f7] centered-items sm:mt-20 sm:pt-10">
      <div className="flex justify-center items-center flex-col w-11/12 p-4 md:mt-10">
        <h1 className="text-4xl text-center font-semibold">
          Manage Your social media accounts effortlessly
        </h1>
        <h1 className="text-[15px] text-center text-[#5b5b5b] centered-items ">
          An all-in-one social media management platform that empowers you to
          create diverse and engaging campaigns.
        </h1>
        <div className="w-[76%] md:w-full lg:w-full h-auto centered-items p-5 mt-10">
          <div className="grid grid-cols-3 gap-y-6 pl-[18px] h-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full  flex content-end">
            <div className="h-auto min-h-[250px] w-[90%] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
              <span className="text-3xl">
                <IconSVG name="schedule" fill="#ff4400" />
              </span>
              <span className="text-2xl font-semibold">Easy to Create</span>
              <p className="text-[#6c6764] text-lg font-normal">
                Create beautiful and high-converting landing pages without a
                developer. Start with an optimized and mobile-responsive
                template
              </p>
            </div>
            <div className="h-auto min-h-[250px] w-[90%] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
              <span className="text-3xl">
                <IconSVG name="manage" fill="#ff4400" />
              </span>
              <span className="text-2xl font-semibold">Easy to Create</span>
              <p className="text-[#6c6764] text-lg font-normal">
                Create beautiful and high-converting landing pages without a
                developer. Start with an optimized and mobile-responsive
                template
              </p>
            </div>
            <div className="h-auto min-h-[250px] w-[90%] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
              <span className="text-3xl">
                <IconSVG name="preview" fill="#ff4400" />
              </span>
              <span className="text-2xl font-semibold">Easy to Create</span>
              <p className="text-[#6c6764] text-lg font-normal">
                Create beautiful and high-converting landing pages without a
                developer. Start with an optimized and mobile-responsive
                template
              </p>
            </div>
            <div className="h-auto min-h-[250px] w-[90%] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
              <span className="text-3xl">
                <IconSVG name="analysis" fill="#ff4400" />
              </span>
              <span className="text-2xl font-semibold">Easy to Create</span>
              <p className="text-[#6c6764] text-lg font-normal">
                Create beautiful and high-converting landing pages without a
                developer. Start with an optimized and mobile-responsive
                template
              </p>
            </div>
            <div className="h-auto min-h-[250px] w-[90%] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
              <span className="text-3xl">
                <IconSVG name="createPost" fill="#ff4400" />
              </span>
              <span className="text-2xl font-semibold">Easy to Create</span>
              <p className="text-[#6c6764] text-lg font-normal">
                Create beautiful and high-converting landing pages without a
                developer. Start with an optimized and mobile-responsive
                template
              </p>
            </div>
            <div className="h-auto min-h-[250px] w-[90%] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
              <span className="text-3xl">
                <IconSVG name="easyToUse" fill="#ff4400" />
              </span>
              <span className="text-2xl font-semibold">Easy to Create</span>
              <p className="text-[#6c6764] text-lg font-normal">
                Create beautiful and high-converting landing pages without a
                developer. Start with an optimized and mobile-responsive
                template
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Properties;

/*

  <div className="space-y-4 mt-14">
        <h1 className="text-4xl text-center font-semibold block">
          Manage Your social media accounts effortlessly
        </h1>
        <h1 className="text-[15px] text-center text-[#5b5b5b] centered-items ">
          An all-in-one social media management platform that empowers you to
          create diverse and engaging campaigns.
        </h1>
      </div>
      <div className="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 w-auto border gap-4 mt-10 mb-10">
        <div className="h-[300px] w-[400px] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
          <span className="text-3xl">
            <IconSVG name="schedule" fill="#ff4400" />
          </span>
          <span className="text-2xl font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-lg font-normal">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
        <div className="h-[300px] w-[400px] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
          <span className="text-3xl">
            <IconSVG name="manage" fill="#ff4400" />
          </span>
          <span className="text-2xl font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-lg font-normal">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
        <div className="h-[300px] w-[400px] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
          <span className="text-3xl">
            <IconSVG name="preview" fill="#ff4400" />
          </span>
          <span className="text-2xl font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-lg font-normal">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
        <div className="h-[300px] w-[400px] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
          <span className="text-3xl">
            <IconSVG name="analysis" fill="#ff4400" />
          </span>
          <span className="text-2xl font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-lg font-normal">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
        <div className="h-[300px] w-[400px] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
          <span className="text-3xl">
            <IconSVG name="createPost" fill="#ff4400" />
          </span>
          <span className="text-2xl font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-lg font-normal">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
        <div className="h-[300px] w-[400px] bg-white border-black rounded-lg border-b-4 p-8 border space-y-5">
          <span className="text-3xl">
            <IconSVG name="easyToUse" fill="#ff4400" />
          </span>
          <span className="text-2xl font-semibold">Easy to Create</span>
          <p className="text-[#6c6764] text-lg font-normal">
            Create beautiful and high-converting landing pages without a
            developer. Start with an optimized and mobile-responsive template
          </p>
        </div>
      </div>
*/
