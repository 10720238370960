/* eslint-disable no-undef */
import React from 'react';

const Navi = () => {
  return (
    <div className='w-full h-[40px] flex items-center pl-5 bg-white sticky top-0 drop-shadow-sm z-50 px-5 '>
      <div className='min-w-[100px] w-[100px] h -ml-2 '>
        <img src={require('../../assets/pngFolder/logo2.png')}/>
      </div>
      <div className='w-full flex justify-end'>
        <span className='w-20 h-5 rounded bg-[#ff4a00] text-white text-xs  rounded-full centered-items'>Start Free</span>
      </div>
    </div>
  );
};

export default Navi;
