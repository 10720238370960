import React, { useEffect } from 'react';

import Navi from './Navi';
import Container from './Container';
import Footer from './Footer';

const index = () => {
  useEffect(() => {
    document.title = 'Sociize | Easiest way to manage your social media accounts';
  }, []);

  return (
    <div className='w-auto h-auto '>
      <Navi/>
      <Container/>
      <Footer/>
    </div>
  );
};

export default index;
