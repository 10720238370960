import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../Header';
import Footer from '../Footer';
import MobileHeader from '../../LandingMobile/Navi';
import Register from './Register';

const RegisterPage = () => {
  const { isMobile } = useSelector(state => state.app);

  return (
    <div className="w-full centered-items flex-col h-auto">
      {
        isMobile ? <MobileHeader/> : <Header/>
      }
      <div className="w-3/12 min-h-[700px] h-auto centered-items min-w-[300px] max-w-[350px]">
        <Register />
      </div>
      <Footer/>
    </div>
  );
};

export default RegisterPage;
