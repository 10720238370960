import React from 'react';

const FallBack = () => {
  return (
    <div className='w-full h-[100vh] flex items-start justify-center'>
      <div className='flex-col h-full w-full centered-items'>
        <div className='h-4/6 flex-col text-center space-y-5'>
          <span className='text-5xl text-gray-500'>Opps!</span>
          <p className='text-gray-500'>Something has gone awry. If you require assistance, please get in touch <a className='text-blue-400 font-bold underline' to={'/'}>with us </a>.</p>
        </div>
      </div>
    </div>
  );
};

export default FallBack;
