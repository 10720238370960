import React from 'react';
import PropTypes from 'prop-types';

const CardHeader = ({ children, additionalClasses }) => {
  return <div className={`text-primaryTextColor ${additionalClasses}`}><span>{children}</span></div>;
};

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string
};

CardHeader.defaultProps = {
  additionalClasses: ''
};

export default CardHeader;
