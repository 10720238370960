import React from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../../assets/icons/IconSVG';

const ModalTitle = ({
  children,
  className,
  icon,
  iconClassNames
}) => {
  return (
    <div className={className}>
      {icon ? <IconSVG className={iconClassNames} icon={icon} /> : null}
      {children}
    </div>
  );
};

ModalTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconClassNames: PropTypes.string,
};

ModalTitle.defaultProps = {
  className: '',
  icon: '',
  iconClassNames: '',
};

export default ModalTitle;
