import React, { useEffect, useState } from 'react';

import withAnimation from '../../../hocs/withAnimation';
import AccountDataRow from './AccountDataRow';

const ConnectionTable = ({ userDatas }) => {
  const [isDetailsOpened, setIsDetailsOpened] = useState(-1);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) =>{
    if (e.target.id !== 'detailOption' && e.target.id !== 'detailButton') {
      setIsDetailsOpened(-1);
    }
  };

  return userDatas.map(({ socialMediaName, accountName, connectionStatus }, index) => (
    <AccountDataRow
      key={index}
      isDetailsOpened={isDetailsOpened}
      setIsDetailsOpened={setIsDetailsOpened}
      index = {index}
      socialMediaName={socialMediaName}
      accountName={accountName}
      connectionStatus={connectionStatus}
    />
  )
  );
};

const container = {
  visible: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const animationConfigures = {
  initial: 'hidden',
  animate: 'visible',
  variants: container,
  className: 'text-secondaryTextColor'
};

export default withAnimation( ConnectionTable, 'tbody', animationConfigures);
