import React from 'react';
import { useSelector } from 'react-redux';

import Login from './Login';
import Header from '../Header';
import Footer from '../Footer';
import MobileHeader from '../../LandingMobile/Navi';

const LoginPage = () => {
  const { isMobile } = useSelector(state => state.app);

  return (
    <div className="w-full centered-items flex-col">
      {
        isMobile ? <MobileHeader/> : <Header/>
      }
      <div className="w-3/12 h-[700px] centered-items min-w-[300px] max-w-[350px]">
        <Login />
      </div>
      <Footer/>
    </div>
  );
};

export default LoginPage;
