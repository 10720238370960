import { put, select, takeEvery } from 'redux-saga/effects';

import {
  FACEBOOK_LOGIN_FAILURE,
  FACEBOOK_LOGIN_REQUEST,
  FACEBOOK_LOGIN_SUCCESS,
} from '../actionTypes';
import api from '../api';
import { getUserID } from '../selectors';
import { getData } from '../api/utils';

function* facebookLogin (action) {
  try {
    // get user id
    const userID = yield select(getUserID);
    if (!userID) {
      throw new Error('User id is not found');
    }

    const response = yield api.facebookLogin({ ...action.payload, userID });
    const facebookUser = getData(response);

    // TODO: for now we are not checking if the user is already registered
    if (facebookUser.userID) {
      yield put({
        type: FACEBOOK_LOGIN_SUCCESS,
        payload: facebookUser,
      });
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: FACEBOOK_LOGIN_FAILURE
    });
  }
}

const socialSaga = [
  takeEvery(FACEBOOK_LOGIN_REQUEST, facebookLogin),
];

export default socialSaga;
